import { defineStore } from "pinia";
import { useAuthStore } from "./auth";
import axios, { AxiosError, AxiosResponse } from "axios";
import Cookies from "js-cookie";

// Assuming VUE_APP_BASE_API_URL is defined in your .env file
const baseUrl = process.env.VUE_APP_BASE_API_URL;
const token = Cookies.get("token");

interface ErrorResponse {
  status: number;
  errors: any;
  data?: {
    message: string;
  };
  message: string;
  response?: AxiosResponse;
}

export const useUsersStore = defineStore({
  id: "users",
  state: () => ({
    allUsers: [] as any[],
    UpdateUserError: null as string | null,
    SuccessUpdatingStatus: null as string | null,
    users: [] as any[],
    emailVerificationState: null as string | null,
    AddUserError: null as string | null,
    SuccessAddingStatus: null as string | null,
    fetchedUserData: [] as any, // Add new state for fetched user data
  }),
  getters: {
    getUsers(): any[] {
      return this.users;
    },

    getFetchedUserData(): any {
      return this.fetchedUserData;
    },
    getUpdateUserError(): string | null {
      return this.UpdateUserError;
    },

    getSuccessUpdatingStatus(): string | null {
      return this.SuccessUpdatingStatus;
    },
  },
  actions: {
    async fetchUsers(page: number) {
      try {
        const authStore = useAuthStore();
        if (!token) {
          //console.error('Token not found in cookies');
          return;
        }

        const response = await axios.get(`${baseUrl}/api/v1/users`, {
          params: {
            user_type: "HOME_OWNER",
            page: page, // Use the page parameter when making the API call
          },
          headers: {
            Authorization: `Bearer ${authStore.getToken}`,
          },
        });
        this.users = response.data;
      } catch (error: any) {
        console.error("Error fetching users:", error);
      }
    },
    async addUser(userData: FormData) {
      try {
        const authStore = useAuthStore();
        const token = Cookies.get("token");
        if (!token) {
          //console.error('Token not found in cookies');
          return;
        }

        const config = {
          headers: {
            Authorization: `Bearer ${authStore.getToken}`,
          },
        };

        const response = await axios.post(
          `${baseUrl}/api/v1/users`,
          userData,
          config
        );

        if (response.data.message == "Successfully created user") {
          this.SuccessAddingStatus = "Successfully Created User";
        }
      } catch (error: any) {
        console.error("Error adding user:", error);
        // Handle the error response
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError<ErrorResponse>;
          this.AddUserError = axiosError.response?.data.errors;
          //console.log('ito and data', axiosError.response?.data.message);

          // Log or handle other error details as needed
        }
      }
    },
    async fetchUserData(id: string) {
      try {
        const authStore = useAuthStore();
        const config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `${baseUrl}/api/v1/users/${id}`,
          headers: {
            Authorization: `Bearer ${authStore.getToken}`,
          },
        };

        const response = await axios.request(config);
        this.fetchedUserData = response.data; // Assigning fetched user data to state
      } catch (error: any) {
        console.error("Error fetching user data:", error);
      }
    },
    async updateUser(userId: string, userData: FormData) {
      try {
        const authStore = useAuthStore();
        const token = Cookies.get("token");
        if (!token) {
          // console.error('Token not found in cookies');
          return;
        }
        console.log(userData);
        const config = {
          method: "post", // Change method to 'put' for updating
          maxBodyLength: Infinity,
          url: `${baseUrl}/api/v1/users/${userId}`,
          headers: {
            Authorization: `Bearer ${authStore.getToken}`,
          },
          data: userData,
        };

        const response = await axios.request(config);

        // If the request is successful, update the state accordingly
        if (response.status === 200) {
          this.SuccessUpdatingStatus = "Successfully Updated User";
        }
      } catch (error: any) {
        console.error("Error updating user:", error);
        // Handle the error response
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError<ErrorResponse>;
          this.UpdateUserError = axiosError.response?.data.errors;
          // Log or handle other error details as needed
        }
      }
    },
    async deleteUser(userId: string) {
      try {
        const authStore = useAuthStore();
        if (!token) {
          //console.error('Token not found in cookies');
          return;
        }

        const config = {
          method: "delete",
          url: `${baseUrl}/api/v1/users/${userId}`,
          headers: {
            Authorization: `Bearer ${authStore.getToken}`,
          },
        };

        const response = await axios.request(config);

        // Assuming the request is successful and user is deleted
        //console.log('User deleted successfully:', response.data);

        // Handle any further state updates or actions as needed
      } catch (error: any) {
        console.error("Error deleting user:", error);
        // Handle the error response
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError<ErrorResponse>;
          // Log or handle other error details as needed
        }
      }
    },
    async fetchAllUsers() {
      let allUsers = [] as any[];
      let currentPage = 1;
      let lastPage = 1;

      try {
        const authStore = useAuthStore();
        do {
          const response = await axios.get(
            `${baseUrl}/api/v1/users?user_type=HOME_OWNER&email_verified=1&page=${currentPage}`,
            {
              headers: {
                Authorization: `Bearer ${authStore.getToken}`,
              },
            }
          );

          // Concatenate users from the current page to the allUsers array
          allUsers = allUsers.concat(response.data.data);

          // Update lastPage with the value from the first page
          if (currentPage === 1) {
            lastPage = response.data.last_page;
          }

          currentPage++;
        } while (currentPage <= lastPage);

        // Update allUsers state with the concatenated data
        this.allUsers = allUsers;
      } catch (error) {
        console.error("Error fetching users:", error);
        // Handle error as needed
      }
    },
  },
});
