<template>
  <div class="container-fluid">
    <div class="inner-main-content">
      <MainHeader />
      <Analytics v-if="userRole === 'ADMIN'" />
      <Analytics2 v-if="userRole === 'HOME_OWNER'" />
      <MainSidebar />
      <MainFooter />
    </div>
  </div>
</template>

<script lang="ts">
import { useAuthStore } from '../../store';
import { defineComponent } from 'vue';

import Analytics from '../../components/Dashboard/Analytics/LockersAnalytics.vue';
import Analytics2 from '../../components/Dashboard/Analytics/HomeOwnerDashboard.vue';
import MainFooter from '../../components/Layouts/MainFooter.vue';
import MainSidebar from '../../components/Layouts/MainSidebar.vue';
import MainHeader from '../../components/Layouts/MainHeader.vue';

export default defineComponent({
  name: 'ProjectManagementPage',
  computed: {
    userRole() {
      const user = useAuthStore().getUser;
      // Check if user exists and has a role property
      if (user && user.user_type) {
        return user.user_type;
      } else {
        return null;
      }
    },
  },
  components: {
    Analytics,
    Analytics2,
    MainFooter,
    MainSidebar,
    MainHeader,
  },
});
</script>
