<template>
  <div>
    <PreLoader v-if="isLoading" />
    <div
      :class="[
        'main-content expanded js-container',
        { collapsed: stateStoreInstance.open },
      ]"
    >
      <router-view />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import PreLoader from './components/Layouts/PreLoader.vue';
import stateStore from './utils/store';

export default defineComponent({
  name: 'App',
  components: {
    PreLoader,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  },
  setup() {
    const stateStoreInstance = stateStore;
    return { stateStoreInstance };
  },
});
</script>
