<template>
  <div class="main-content p-0">
    <div class="container-fluid">
      <SubHeader />
      <ResetPassword />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ResetPassword from '../../components/Authentication/ResetPassword/ResetPassword.vue';
import SubHeader from '../../components/Layouts/SubHeader.vue';

export default defineComponent({
  name: 'ResetPasswordPage',
  components: {
    ResetPassword,
    SubHeader,
  },
});
</script>
