<template>
  <div class="wrapper">
    <div class="card border-0 box-shadow border-radius-10 mb-4">
      <div class="card-body p-4">
        <div
          class="card-title d-flex justify-content-between align-items-center mb-3"
        >
          <h3 class="fw-medium fs-18 mb-0">My Locker List</h3>
        </div>
        <div v-if="updatePinStatusCode === '200'" class="mt-3">
          <div class="alert alert-success" role="alert">
            Pin Reset/Update Successful.
          </div>
        </div>
        <div class="all-table scroll-bar h-660">
          <table class="table align-middle mb-0">
            <thead>
              <tr>
                <th
                  class="text-dark fw-medium pt-0 pb-2 fs-14 ps-0"
                  scope="col"
                >
                  Locker Number
                </th>
                <th class="text-dark fw-medium pt-0 pb-2 fs-14" scope="col">
                  Locker Size
                </th>

                <th class="text-dark fw-medium pt-0 pb-2 fs-14" scope="col">
                  Status
                </th>

                <th class="text-dark fw-medium pt-0 pb-2 fs-14" scope="col">
                  Actions
                </th>
                <th
                  class="text-dark fw-medium pt-0 pb-2 fs-14"
                  scope="col"
                ></th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="locker in lockers" :key="locker.id">
                <td class="ps-0 d-flex align-items-center">
                  <img
                    class="wh-30 rounded-circle"
                    src="../../assets/images/dala-box/dala-box.png"
                  />
                  <h5 class="fs-14 text-dark mb-0 ms-2 fw-medium">
                    Locker {{ locker.number }}
                  </h5>
                </td>
                <td>
                  <h5 class="fs-14 text-dark mb-0 ms-2 fw-medium">
                    {{ locker.locker_size_id }}
                  </h5>
                </td>

                <td>
                  <h5
                    class="fs-14 text-dark mb-0 ms-2 fw-medium"
                    :class="{
                      'text-primary': getStatus(locker) === 'With Parcel',
                      'text-success': getStatus(locker) === 'Vacant',
                    }"
                  >
                    {{ getStatus(locker) }}
                  </h5>
                </td>

                <td>
                  <span class="text-body">
                    <!-- Check-square icon -->
                    <vue-feather
                      type="key"
                      class="feather-icon"
                      title="Reset Pin"
                      data-bs-toggle="modal"
                      data-bs-target="#initiateUpdateModal"
                      @click="
                        getSelectedLockerInfo(
                          locker.id,
                          locker.number,
                          locker.disabled_at,
                          locker.has_current_delivery,
                          locker.user_id
                        )
                      "
                      ><span class="icon-space"></span
                    ></vue-feather>
                  </span>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <div
      class="modal fade"
      id="initiateUpdateModal"
      tabindex="-1"
      aria-labelledby="initiateUpdateModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="initiateUpdateModalLabel">
              Confirm Reset/Update Locker Pin
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="btnDismissInitiateUpdateModal"
            ></button>
          </div>
          <div class="modal-body">
            <p class="text-body fs-14">
              Are you sure you want to
              <span style="font-weight: bold; color: rgb(13, 126, 60)">
                RESET/UPDATE
              </span>
              <span>Locker Pin </span>
              of
              <span style="font-weight: bold; color: rgb(240, 12, 12)"
                >Locker {{ selectedLockerNumber }}</span
              >?
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#confirmUpdateModal"
              @click="inititaUpdate()"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="confirmUpdateModal"
      tabindex="-1"
      aria-labelledby="confirmUpdateModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="confirmUpdateModalLabel">
              Reset/Update Locker Pin
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="btnDismissconfirmUpdateModal"
            ></button>
          </div>
          <div class="container">
            <div
              v-if="updatePinStatus && updatePinStatusCode !== '200'"
              class="mt-3"
            >
              <div class="wrapper-alert">
                <div class="alert alert-danger p-3" role="alert">
                  <ul>
                    <li v-for="(errors, field) in updatePinStatus" :key="field">
                      <span v-for="(error, index) in errors" :key="index">{{
                        error
                      }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div id="pinUpdateForm">
            <div class="modal-body">
              <div class="mb-3">
                <label for="new_pin" class="form-label">New PIN</label>
                <input
                  type="password"
                  class="form-control"
                  id="new_pin"
                  name="new_pin"
                  required
                  v-model="newPin"
                  @input="
                    errorMessageNewPin = '';
                    clearUpdatePin();
                  "
                  maxlength="6"
                  pattern="[0-9]{6}"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                />
              </div>
              <div class="col text-danger text-end" v-if="errorMessageNewPin">
                {{ errorMessageNewPin }}
              </div>

              <div class="mb-3">
                <label for="new_pin_confirmation" class="form-label"
                  >Confirm New PIN</label
                >
                <input
                  type="password"
                  class="form-control"
                  id="new_pin_confirmation"
                  name="new_pin_confirmation"
                  required
                  v-model="newPinConfirmation"
                  @input="
                    errorMessageNewPinConfirmation = '';
                    clearUpdatePin();
                  "
                  maxlength="6"
                  pattern="[0-9]{6}"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                />
              </div>
              <div
                class="col text-danger text-end"
                v-if="errorMessageNewPinConfirmation"
              >
                {{ errorMessageNewPinConfirmation }}
              </div>

              <div class="mb-3">
                <label for="otp" class="form-label">OTP</label>
                <input
                  type="text"
                  class="form-control"
                  id="otp"
                  name="otp"
                  required
                  v-model="otp"
                  @input="
                    errorMessageOTP = '';
                    clearUpdatePin();
                  "
                  maxlength="6"
                  pattern="[0-9]{6}"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                />
              </div>
              <div class="col text-danger text-end" v-if="errorMessageOTP">
                {{ errorMessageOTP }}
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click.prevent="updatePin()"
              >
                Update Pin
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useLockersStore } from "@/store/lockers";

export default {
  name: "MyLockerList",

  data() {
    return {
      lockers: [],
      selectedUserId: "",
      selectedLockerNumber: "",
      selectedLockerID: "",
      selectedLockerStatus: "",
      selectedLockerHasParcel: "",
      errorMessageNewPin: "",
      errorMessageNewPinConfirmation: "",
      errorMessageOTP: "",
      newPinConfirmation: "",
      newPin: "",
      otp: "",
    };
  },
  async mounted() {
    await this.fetchMyLockers();
  },
  computed: {
    updatePinStatus() {
      return useLockersStore().updatePinStatus;
    },
    updatePinStatusCode() {
      return useLockersStore().updatePinStatusCode;
    },
  },
  methods: {
    async fetchMyLockers() {
      try {
        const lockersStore = useLockersStore();
        await lockersStore.fetchMyLockers();
        this.lockers = lockersStore.myLockers;
      } catch (error) {
        console.error("Error fetching lockers:", error);
      }
    },
    getStatus(locker) {
      if (locker.disabled_at !== null) {
        return "Disabled";
      } else {
        return "Active";
      }
    },
    getSelectedLockerInfo(
      lockerId,
      lockerNumber,
      lockerStatus,
      hasdelivery,
      selectedUserId
    ) {
      this.selectedLockerID = lockerId;
      this.selectedLockerNumber = lockerNumber;
      this.selectedLockerStatus = lockerStatus;
      this.selectedLockerHasParcel = hasdelivery;
      this.selectedUserId = selectedUserId;
      if (this.updatePinStatus !== "") {
        const lockersStore = useLockersStore();
        lockersStore.clearUpdatePinStatus();
      }
    },
    convertToGMT8(dateTimeString) {
      const date = new Date(dateTimeString); // Create a Date object from the given date string
      const gmtOffset = 8; // GMT+8 timezone offset
      const gmt8Date = new Date(date.getTime() + gmtOffset * 60 * 60 * 1000); // Add offset in milliseconds

      // Format the date as desired (e.g., "05 Feb, 2023")
      const options = {
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
        timeZone: "Asia/Singapore", // Assuming GMT+8 represents the Singapore timezone
      };
      return gmt8Date.toLocaleString("en-US", options);
    },
    async inititaUpdate(lockerNumber) {
      const lockersStore = useLockersStore();
      await lockersStore.forgotPin(this.selectedLockerID);
      document.getElementById("btnDismissInitiateUpdateModal").click();
    },
    async updatePin() {
      // Reset error messages
      this.errorMessageNewPin = "";
      this.errorMessageNewPinConfirmation = "";
      this.errorMessageOTP = "";

      // Check if any required field is missing
      if (this.newPin.trim() === "") {
        this.errorMessageNewPin = "New PIN is required.";
        return;
      }

      if (!this.newPinConfirmation || this.newPinConfirmation.trim() === "") {
        this.errorMessageNewPinConfirmation = "Confirm New PIN is required.";
        return;
      }

      if (!this.otp || this.otp.trim() === "") {
        this.errorMessageOTP = "OTP is required.";
        return;
      }

      // Check if new PIN and confirm new PIN match
      if (this.newPin !== this.newPinConfirmation) {
        this.errorMessageNewPinConfirmation =
          "New PIN and Confirm New PIN must match.";
        return;
      }

      // Your logic to update the PIN goes here
      const lockersStore = useLockersStore();
      await lockersStore.updateLockerPin(
        this.selectedLockerID,
        this.newPin,
        this.otp
      );

      if (this.updatePinStatusCode === "200") {
        this.newPinConfirmation = "";
        this.newPin = "";
        this.otp = "";
        document.getElementById("btnDismissconfirmUpdateModal").click();
      }
    },
    clearUpdatePin() {
      const lockersStore = useLockersStore();
      lockersStore.clearUpdatePinStatus();
    },
  },
};
</script>
<style scoped>
.actions-container {
  text-align: left; /* Align the icons to the left */
}

.feather-icon {
  transition: transform 0.3s ease;
}

.feather-icon:hover {
  transform: scale(1.2);
}

.icon-space {
  display: inline-block;
  width: 10px;
}

.wrapper-alert {
  padding: 0 15px; /* Adjust as needed */
}

/* Style to extend the last column */
.table th:last-child,
.table td:last-child {
  width: calc(100% - 100px); /* Adjust as needed */
  /* 100px is the approximate width of the other columns combined */
}
</style>
