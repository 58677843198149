import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../../assets/images/logo.png'
import _imports_1 from '../../assets/images/white-logo.png'
import _imports_2 from '../../assets/images/user/user-1.jpg'


const _hoisted_1 = {
  id: "darkModeHeader",
  class: "header-area bg-white mb-4"
}
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "row align-items-center" }
const _hoisted_4 = { class: "col-lg-6 col-md-7" }
const _hoisted_5 = { class: "left-header-content" }
const _hoisted_6 = { class: "d-lg-flex d-md-flex align-items-center ps-0 mb-0 list-unstyled" }
const _hoisted_7 = { class: "mb-3 mb-md-0 mb-lg-0" }
const _hoisted_8 = { class: "d-flex justify-content-between align-items-center cw-273" }
const _hoisted_9 = { class: "col-lg-6 col-md-5" }
const _hoisted_10 = { class: "float-lg-end float-md-end" }
const _hoisted_11 = { class: "right-header-content list-unstyled d-flex justify-content-lg-end justify-content-md-end justify-content-center align-items-center mb-0 ps-0" }
const _hoisted_12 = { class: "dropdown date" }
const _hoisted_13 = {
  class: "btn bg-transparent p-0 border-0 p-0 position-relative badge",
  type: "button",
  "data-bs-toggle": "dropdown",
  "aria-expanded": "false"
}
const _hoisted_14 = { class: "dropdown-menu dropdown-lg p-0 rounded-0 border-0" }
const _hoisted_15 = { class: "today-date" }
const _hoisted_16 = { class: "fw-medium" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { class: "dropdown notifications" }
const _hoisted_19 = {
  class: "btn bg-transparent border-0 p-0 position-relative",
  type: "button",
  "data-bs-toggle": "dropdown",
  "aria-expanded": "false"
}
const _hoisted_20 = { class: "dropdown-menu dropdown-lg p-0 rounded-10 border-0 box-shadow" }
const _hoisted_21 = { class: "notification-menu scroll-bar h-400" }
const _hoisted_22 = { class: "d-flex align-items-center" }
const _hoisted_23 = { class: "avatar-md rounded-circle text-center flex-shrink-0" }
const _hoisted_24 = { class: "dropdown user-profile" }
const _hoisted_25 = {
  class: "d-flex align-items-center user-control text-decoration-none",
  "aria-expanded": "false"
}
const _hoisted_26 = { class: "flex-grow-1 ms-3" }
const _hoisted_27 = { class: "d-flex align-items-center justify-content-between" }
const _hoisted_28 = {
  key: 0,
  class: "me-5 d-none d-lg-block"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_vue_feather = _resolveComponent("vue-feather")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("ul", _hoisted_6, [
              _createElementVNode("li", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_router_link, {
                    to: "/",
                    class: "flex-shrink-0"
                  }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createElementVNode("img", {
                        src: _imports_0,
                        class: "main-logo",
                        alt: "main-logo"
                      }, null, -1),
                      _createElementVNode("img", {
                        src: _imports_1,
                        class: "d-none white-logo",
                        alt: "white-logo"
                      }, null, -1)
                    ])),
                    _: 1
                  }),
                  _createElementVNode("div", null, [
                    _createElementVNode("button", {
                      class: "border-0 p-0 bg-transparent js-sidebar-expand flex-grow-1 ms-3 text-sm-end menu-bergur",
                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.stateStoreInstance.onChange && _ctx.stateStoreInstance.onChange(...args)))
                    }, [
                      _withDirectives(_createElementVNode("div", {
                        id: "closeBtn",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleButtonVisibility('openBtn')))
                      }, [
                        _createVNode(_component_vue_feather, { type: "menu" })
                      ], 512), [
                        [_vShow, _ctx.showOpenBtn]
                      ]),
                      _withDirectives(_createElementVNode("div", {
                        id: "openBtn",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleButtonVisibility('closeBtn')))
                      }, [
                        _createVNode(_component_vue_feather, { type: "x" })
                      ], 512), [
                        [_vShow, _ctx.showCloseBtn]
                      ])
                    ])
                  ])
                ])
              ]),
              _cache[4] || (_cache[4] = _createElementVNode("li", { class: "d-none d-lg-block d-md-none" }, [
                _createElementVNode("h3", { class: "mb-0" }, "Dashboard")
              ], -1))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("ul", _hoisted_11, [
              _createElementVNode("li", null, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("button", _hoisted_13, [
                    _createVNode(_component_vue_feather, {
                      class: "t-0",
                      type: "calendar"
                    })
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.formattedDate), 1),
                      _createVNode(_component_vue_feather, { type: "calendar" })
                    ])
                  ])
                ])
              ]),
              (_ctx.userMobile === null)
                ? (_openBlock(), _createElementBlock("li", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("button", _hoisted_19, [
                        _createVNode(_component_vue_feather, { type: "bell" }),
                        _cache[5] || (_cache[5] = _createElementVNode("span", { class: "position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" }, " 1 ", -1))
                      ]),
                      _createElementVNode("div", _hoisted_20, [
                        _cache[8] || (_cache[8] = _createElementVNode("h6", { class: "dropdown-item-text m-0 py-3 border-bottom d-flex justify-content-between align-items-center" }, " Notifications ", -1)),
                        _createElementVNode("div", _hoisted_21, [
                          _createVNode(_component_router_link, {
                            to: "/verify-mobile",
                            class: "dropdown-item py-3"
                          }, {
                            default: _withCtx(() => [
                              _cache[7] || (_cache[7] = _createElementVNode("small", { class: "float-end text-body ps-2" }, " Authentication ", -1)),
                              _createElementVNode("div", _hoisted_22, [
                                _createElementVNode("div", _hoisted_23, [
                                  _createVNode(_component_vue_feather, { type: "phone" })
                                ]),
                                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "flex-grow-1 ms-2 text-truncate" }, [
                                  _createElementVNode("h6", { class: "my-0 fw-medium text-dark fs-14" }, " Mobile Verifications "),
                                  _createElementVNode("small", { class: "text-body mb-0" }, " Complete your registration. ")
                                ], -1))
                              ])
                            ]),
                            _: 1
                          })
                        ])
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("li", null, [
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("div", _hoisted_25, [
                    _cache[9] || (_cache[9] = _createElementVNode("div", { class: "flex-shrink-0" }, [
                      _createElementVNode("img", {
                        class: "rounded-circle",
                        src: _imports_2,
                        alt: "user-1"
                      })
                    ], -1)),
                    _createElementVNode("div", _hoisted_26, [
                      _createElementVNode("div", _hoisted_27, [
                        (_ctx.userData)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                              _createElementVNode("h3", null, _toDisplayString(_ctx.userData.first_name) + " " + _toDisplayString(_ctx.userData.last_name), 1),
                              _createElementVNode("span", null, _toDisplayString(_ctx.userData.user_type
                              .replace(/_/g, " ")
                              .charAt(0)
                              .toUpperCase() +
                            _ctx.userData.user_type.replace(/_/g, " ").slice(1)), 1)
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}