<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-8">
        <div class="card border-0 box-shadow border-radius-10 mb-4">
          <div class="card-body p-0">
            <div class="position-relative">
              <img
                class="w-100 h-400"
                src="../../../assets/images/profile-bg.jpg"
                alt="ad-user-bg-two"
              />
            </div>

            <div class="d-flex justify-content-between align-items-center p-4">
              <div class="users-profile top-125 d-flex align-items-end ms-0">
                <div class="position-relative">
                  <img
                    class="w-100 h-400"
                    src="../../../assets/images/publicimages/default-profile.png"
                    alt="User Profile Picture"
                  />
                  <div
                    class="product-upload position-absolute bottom-0 end-0"
                    style="right: 5px !important; bottom: 5px !important"
                  >
                    <label for="file-upload" class="file-upload mb-0">
                      <img
                        src="../../../assets/images/upload-4.svg"
                        alt="upload-4"
                      />
                    </label>
                    <input
                      id="file-upload"
                      type="file"
                      @change="uploadProfilePicture($event)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-8">
        <div class="card border-0 box-shadow border-radius-10 mb-4">
          <div class="card-body p-4">
            <div
              class="card-title d-flex justify-content-between align-items-center mb-3"
            >
              <h3 class="fw-medium fs-18 mb-0">Update User Profile</h3>
            </div>
            <div v-if="UpdateUserHasError && showError" class="mt-3">
              <div class="alert alert-danger" role="alert">
                <ul class="error-list">
                  <li
                    v-for="(errorMessages, fieldName) in errorObj"
                    :key="fieldName"
                  >
                    {{ errorMessages[0] }}
                  </li>
                </ul>
              </div>
            </div>

            <!-- Update user error message -->
            <div v-if="updateError" class="mt-3">
              <div class="alert alert-danger" role="alert">
                Error updating user: {{ updateError }}
              </div>
            </div>

            <!-- Update user success alert -->
            <div v-if="SuccessUpdatingStatus && showSuccessAlert" class="mt-3">
              <div class="alert alert-success" role="alert">
                User Updated Successfully!
              </div>
            </div>

            <!-- Update user form -->
            <div>
              <form @submit.prevent="submitForm" ref="userForm">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group mb-4">
                      <label class="label">First Name</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter first name"
                        v-model="firstName"
                        required
                        :readonly="true"
                      />
                      <div v-if="!isFirstNameValid" class="text-danger">
                        First name is required
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group mb-4">
                      <label class="label">Last Name</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter last name"
                        v-model="lastName"
                        required
                        :readonly="true"
                      />
                      <div v-if="!isLastNameValid" class="text-danger">
                        Last name is required
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group mb-4">
                      <label class="label">Email Address</label>
                      <input
                        style="color: black"
                        type="email"
                        class="form-control"
                        placeholder="Enter email address"
                        v-model="email"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group mb-4">
                      <label class="label">Phone</label>
                      <input
                        style="color: black"
                        type="tel"
                        class="form-control"
                        placeholder="Enter phone number"
                        v-model="mobile"
                        required
                        @input="validateMobile"
                      />
                      <div v-if="!isValidMobile" class="text-danger">
                        Phone number must be 11 digits
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group mb-4">
                      <label class="label">Display Name</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter display name"
                        v-model="displayName"
                        required
                        :readonly="true"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group mb-4">
                      <label class="label">House Number</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter house number"
                        v-model="houseNumber"
                        required
                        :readonly="true"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group mb-4">
                      <label class="label">Address</label>
                      <input
                        style="color: black"
                        type="text"
                        class="form-control"
                        placeholder="Enter your address"
                        v-model="address"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group mb-4">
                      <label class="label">User Type</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="usertype"
                        required
                        :readonly="true"
                      />
                    </div>
                  </div>
                </div>

                <div class="text-right mt-5">
                  <button class="default-btn" type="submit">Update</button>
                  <button
                    @click.prevent="cancelAction()"
                    class="default-btn border-btn ms-3"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useUsersStore } from "@/store/users";
import router from "@/router";

export default {
  name: "UpdateUser",
  data() {
    return {
      userId: "",
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      displayName: "",
      houseNumber: "",
      address: "",
      usertype: "",
      isValidMobile: true,
      isValidEmail: true,
      formSubmitted: false,
      showError: true,
      showSuccessAlert: true,
      errorObj: {},
      updateError: null,
      isLocked: false,
      isFirstNameValid: true,
      isLastNameValid: true,
      isEmailValid: true,
      profilePicture: null,
    };
  },
  computed: {
    UpdateUserHasError() {
      return !!this.UpdateUserError;
    },
    fetchedUserData() {
      return useUsersStore().getFetchedUserData();
    },
    UpdateUserError() {
      return useUsersStore().UpdateUserError;
    },
    SuccessUpdatingStatus() {
      return useUsersStore().getSuccessUpdatingStatus;
    },
  },
  methods: {
    async uploadProfilePicture(event) {
      const file = event.target.files[0];
      if (!file) return;

      this.profilePicture = file;
    },
    async submitForm() {
      this.formSubmitted = true;

      if (!this.$refs.userForm.checkValidity()) {
        return;
      }

      this.isEmailValid = this.email !== "";

      // Custom validation for mobile number
      const mobileRegex = /^\d{11}$/; // 11 digits
      this.isValidMobile = mobileRegex.test(this.mobile);

      if (!this.isValidMobile) {
        return;
      }

      try {
        const store = useUsersStore();
        const userData = new FormData();
        const userId = this.$route.params.id;
        userData.append("_method", "PUT");
        userData.append("first_name", this.firstName);
        userData.append("last_name", this.lastName);
        userData.append("email", this.email);
        userData.append("mobile", this.mobile);
        userData.append("display_name", this.displayName);
        userData.append("address_house_number", this.houseNumber);
        userData.append("address", this.address);

        if (this.profilePicture) {
          userData.append("img", this.profilePicture);
        }
        await store.updateUser(userId, userData);

        if (this.UpdateUserHasError) {
          this.errorObj = this.UpdateUserError;

          setTimeout(() => {
            this.showError = false;

            useUsersStore().UpdateUserError = null;
            this.showError = true;
          }, 3000);
        }

        if (this.SuccessUpdatingStatus) {
          setTimeout(() => {
            this.showSuccessAlert = false;

            useUsersStore().SuccessUpdatingStatus = null;
            this.showSuccessAlert = true;
          }, 3000);
        }
      } catch (error) {
        console.error("Server response error:", error);
        this.updateError =
          error.message || "Unknown error occurred during update";
        setTimeout(() => {
          this.updateError = null;
        }, 5000);
      }
    },
    redirectToHomePage() {
      router.push("/");
    },
    redirectToUserList() {
      router.push("/user-list");
    },
    async getUserData() {
      try {
        const userId = this.$route.params.id;
        const store = useUsersStore();
        try {
          await store.fetchUserData(userId); // Call the fetchUserData action with the user ID
          // Once data is fetched, log the user data
          this.assignUserDataToForm(store.fetchedUserData);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },
    assignUserDataToForm(userData) {
      this.firstName = userData.first_name;
      this.lastName = userData.last_name;
      this.email = userData.email;
      this.mobile = userData.mobile;
      this.displayName = userData.display_name;
      this.houseNumber = userData.address_house_number;
      this.address = userData.address;
      this.usertype = userData.user_type;
    },
    validateMobile() {
      const mobileRegex = /^\d{11}$/; // 11 digits
      this.isValidMobile = mobileRegex.test(this.mobile);
    },
    async cancelAction() {
      const userStore = useUsersStore();
      this.firstName = userStore.getFetchedUserData.first_name;
      this.lastName = userStore.getFetchedUserData.last_name;
      this.email = userStore.getFetchedUserData.email;
      this.mobile = userStore.getFetchedUserData.mobile;
      this.displayName = userStore.getFetchedUserData.display_name;
      this.houseNumber = userStore.getFetchedUserData.address_house_number;
      this.address = userStore.getFetchedUserData.address;

      this.isValidMobile = true;
      this.isValidEmail = true;
    },
  },
  mounted() {
    this.getUserData();
  },
};
</script>

<style scoped>
/* Your component-specific styles */

.error-list {
  margin-bottom: 0; /* Remove bottom margin */
}

.error-list li {
  padding-bottom: 0; /* Remove bottom padding */
}

.form-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px; /* Add margin to separate form actions from form fields */
}

.default-btn,
.default-btn {
  background-color: #007bff; /* Update button color */
  color: #fff; /* Update text color */
  border: none; /* Remove border */
}

.buttons {
  width: 200px;
  margin: 0 auto;
  display: inline;
}

.action_btn {
  width: 200px;
  margin: 0 auto;
  display: inline;
}
</style>
