import axios from "axios";
import { useAuthStore } from "./auth";
import { defineStore } from "pinia";

// Define your base API URL
const baseUrl = process.env.VUE_APP_BASE_API_URL;

// Define the delivery history interface
interface DeliveryHistory {
  id: number;
  number: string;
  date: string;
  collectedDate: string;
  parcelImage: string;
  courierImage: string;
}

export const useHistoryStore = defineStore({
  id: "history",
  state: () => ({
    deliveryHistory: [] as any[],
    error: null as string | null,
    myTotalDeliveries: null as string | null,
  }),
  actions: {
    async fetchDeliveryHistory() {
      const authStore = useAuthStore();
      try {
        const config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `${baseUrl}/api/v1/my-deliveries`,
          headers: {
            Authorization: `Bearer ${authStore.getToken}`,
          },
        };

        const response = await axios.get(`${baseUrl}/api/v1/my-deliveries`, {
          headers: {
            Authorization: `Bearer ${authStore.getToken}`,
          },
        });

        this.deliveryHistory = response.data.data;

        //console.log(this.deliveryHistory);
      } catch (error: any) {
        this.error = error.message || error;
        throw new Error(error);
      }
    },
    async getMyTotalDeliveries() {
      const authStore = useAuthStore();
      try {
        const config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `${baseUrl}/api/v1/my-deliveries`,
          headers: {
            Authorization: `Bearer ${authStore.getToken}`,
          },
        };

        const response = await axios.get(`${baseUrl}/api/v1/my-deliveries`, {
          headers: {
            Authorization: `Bearer ${authStore.getToken}`,
          },
        });

        this.myTotalDeliveries = response.data.data.length;
        //console.log(this.deliveryHistory);
      } catch (error: any) {
        this.error = error.message || error;
        throw new Error(error);
      }
    },
  },
  getters: {
    getDeliveryHistory(): DeliveryHistory[] {
      return this.deliveryHistory;
    },
    getError(): string | null {
      return this.error;
    },
  },
});
