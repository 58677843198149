<template>
  <div class="row justify-content-center">
    <div class="col-xxl-3 col-md-6">
      <div class="card border-0 box-shadow border-radius-10 mb-4">
        <div class="card-body p-4">
          <div class="d-flex">
            <div class="flex-grow-1 me-3">
              <h3 class="fw-medium fs-18">Active Lockers</h3>
              <span class="text-body">Total</span>
              <h1 class="fw-medium fs-30 mb-3" v-if="dashboardData">
                {{ dashboardData.active_lockers }}
              </h1>

              <p class="fs-12 text-dark lh-1 mt-2">
                <span class="text-success">Lockers available</span> for
                Deliveries
              </p>
            </div>
            <div class="flex-shrink-0">
              <img
                src="../../../assets/images/icons/project-icon-1.svg"
                alt="project-icon-1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xxl-3 col-md-6">
      <div class="card border-0 box-shadow border-radius-10 mb-4">
        <div class="card-body p-4">
          <div class="d-flex">
            <div class="flex-grow-1 me-3">
              <h3 class="fw-medium fs-18">Inactive Lockers</h3>
              <span class="text-body">Total</span>
              <h1 class="fw-medium fs-30 mb-3" v-if="dashboardData">
                {{ dashboardData.inactive_lockers_count }}
              </h1>
              <p class="fs-12 text-dark lh-1 mt-2">
                <span class="text-danger">Inactive lockers</span> not available
                for Deliveries
              </p>
            </div>
            <div class="flex-shrink-0">
              <img
                src="../../../assets/images/icons/project-icon-2.svg"
                alt="project-icon-2"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xxl-3 col-md-6">
      <div class="card border-0 box-shadow border-radius-10 mb-4">
        <div class="card-body p-4">
          <div class="d-flex">
            <div class="flex-grow-1 me-3">
              <h3 class="fw-medium fs-18">Deliveries</h3>
              <span class="text-body">Total</span>
              <h1 class="fw-medium fs-30 mb-3" v-if="dashboardData">
                {{ dashboardData.deliveries }}
              </h1>

              <p class="fs-12 text-dark lh-1 mt-2">
                <span class="text-success">deliveries</span> since Inception
              </p>
            </div>
            <div class="flex-shrink-0">
              <img
                src="../../../assets/images/icons/project-icon-3.svg"
                alt="project-icon-3"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- The remaining card elements -->
  </div>
</template>

<script>
import { useDashboardStore } from '../../../store/dashboard';
import { useAuthStore } from '../../../store/auth';

export default {
  data() {
    return {
      dashboardStore: useDashboardStore(),
    };
  },
  mounted() {
    if (useAuthStore().user) {
      this.dashboardStore.fetchDashboardData();
    }
  },
  computed: {
    dashboardData() {
      return this.dashboardStore.getDashboardData;
    },
    error() {
      return this.dashboardStore.getError;
    },
  },
};
</script>
