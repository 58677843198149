<template>
  <div class="container-fluid">
    <div class="inner-main-content">
      <underconstruction />
      <MainSidebar />
      <MainFooter />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import underconstruction from '../components/Starter/SoonContent.vue';
import MainFooter from '../components/Layouts/MainFooter.vue';
import MainSidebar from '../components/Layouts/MainSidebar.vue';

export default defineComponent({
  name: 'SoonPage',
  components: {
    underconstruction,
    MainFooter,
    MainSidebar,
  },
});
</script>
