<template>
  <div>
    <SubHeader />
    <ForgetPassword />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ForgetPassword from '../../components/Authentication/ForgetPassword/ForgetPassword.vue';
import SubHeader from '../../components/Layouts/SubHeader.vue';

export default defineComponent({
  name: 'ForgetPasswordPage',
  components: {
    ForgetPassword,
    SubHeader,
  },
});
</script>
