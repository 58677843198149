<template>
  <div>
    <SubHeader />
    <div class="centered-box">
      <div class="box">
        <div class="text-center mb-1">
          <h4 class="fs-20 fw-semibold mb-2">Dalasmartlockbox</h4>
          <img
            class="rounded-circle mb-3"
            src="../../assets/images/logo-box-2.png"
            alt="DALA-logo"
          />
        </div>
        <div class="text-center mb-4">
          <h4 class="fs-20 fw-semibold mb-2">Sign Up</h4>
          <p class="fs-14 text-body">
            Already have an account?
            <router-link
              to="/sign-in"
              class="text-primary text-decoration-none"
            >
              Sign In
            </router-link>
          </p>
        </div>
        <form>
          <div class="form-group mb-4">
            <label class="label">Your Name</label>
            <input type="text" class="form-control" placeholder="Enter name" />
          </div>
          <div class="form-group mb-4">
            <label class="label">Email Address</label>
            <input
              type="text"
              class="form-control"
              placeholder="example@gmail.com"
            />
          </div>
          <div class="form-group mb-4">
            <label class="label">Your Password</label>
            <input
              type="password"
              id="password"
              class="form-control"
              value="password"
            />
          </div>
          <div class="form-group mb-4">
            <label class="label">Confirm Password</label>
            <input
              type="password"
              id="confirm-password"
              class="form-control"
              value="password"
            />
          </div>
          <div class="form-group mb-4">
            <div class="c-form-check form-check">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label
                class="form-check-label fs-14 text-body ms-0"
                for="flexCheckDefault"
              >
                I accept the
                <a href="#" class="text-decoration-none text-primary"
                  >Terms and Conditions</a
                >
              </label>
            </div>
          </div>
          <div class="form-group mb-4">
            <button type="submit" class="default-btn w-100 d-block">
              Sign Up
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import SubHeader from '../../components/Layouts/SubHeader.vue';
export default {
  name: 'CenteredBox',
  components: { SubHeader },
};
</script>

<style scoped>
.centered-box {
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.box {
  width: 500px; /* Increase width as needed */
  background-color: white; /* Changed background color to white */
  border-radius: 10px;
  padding: 20px; /* Added padding */
}

.signup-form {
  width: 100%; /* Take full width of the box */
}

.form-group {
  margin-bottom: 20px;
}

.label {
  display: block;
  font-weight: bold;
}

input {
  width: calc(
    100% - 16px
  ); /* Adjusted width to account for padding and border */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
}
</style>
