<template>
  <div>
    <SubHeader />
    <div class="centered-box">
      <div class="box">
        <div class="card-body p-30">
          <img
            class="rounded-circle mb-3 centered-image"
            src="../../assets/images/logo-box-2.png"
            alt="DALA-logo"
          />
          <div class="text-center mb-4">
            <h4 class="fs-20 fw-semibold mb-2">
              Welcome to Dala Smart Lockbox
            </h4>
          </div>

          <div>
            <img
              class="rounded-circle mb-3 centered-image"
              src="../../assets/images/email.png"
              alt="email"
            />
            <div
              v-if="resendEmailVerificationState"
              class="alert alert-success"
              role="alert"
            >
              A verification email has been sent to your registered email
              address. Please check your inbox and click on the link provided to
              verify your email
            </div>

            <div
              v-if="
                emailVerificationState === 'Successfully verified email' &&
                !resendEmailVerificationState
              "
            >
              <h4 class="fs-18 fw-semibold mb-2 text-center text-success">
                Success! Please SignIn
              </h4>
            </div>
            <div
              v-if="
                emailVerificationState !== 'Successfully verified email' &&
                !resendEmailVerificationError
              "
            >
              <h4 class="fs-18 fw-semibold mb-2 text-center text-danger">
                Failed! Invalid or Expired Token.
              </h4>
              If you need to request for email verification kindly click:
              <a
                @click="sendEmailVerifications"
                style="cursor: pointer; color: blue; text-decoration: underline"
                >Request for email verification</a
              >
            </div>
            <div v-else-if="resendEmailVerificationError">
              <h4 class="fs-18 fw-semibold mb-2 text-center text-danger">
                {{ resendEmailVerificationError }}!
              </h4>
            </div>
          </div>
          <br />
          <form>
            <div class="form-group">
              <router-link
                to="/sign-in"
                class="default-btn w-100 d-block text-center text-white text-decoration-none"
              >
                Sign In
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubHeader from "../../components/Layouts/SubHeader.vue";
import { useMailStore } from "@/store/mail";

export default {
  name: "ConfirmEmail",
  components: { SubHeader },
  computed: {
    emailVerificationState() {
      return useMailStore().emailVerificationState;
    },
    resendEmailVerificationState() {
      return useMailStore().resendEmailVerificationState;
    },
    resendEmailVerificationError() {
      return useMailStore().resendEmailVerificationError;
    },
  },
  mounted() {
    this.confirmEmail();
  },
  methods: {
    async confirmEmail() {
      try {
        const mailStore = useMailStore();
        const payload = this.extractToken();
        const token = payload.split("?")[0];
        const queryParams = new URLSearchParams(window.location.search);
        const email = queryParams.get("email");
        await mailStore.verifyEmail(email, decodeURIComponent(token));
      } catch (error) {
        console.error("Error verifying email:", error);
      }
    },
    extractToken() {
      const url = window.location.href;
      const parts = url.split("/");
      const payload = parts.slice(4).join("/");
      return payload;
    },

    async sendEmailVerifications() {
      const mailStore = useMailStore();
      await mailStore.resendVerificationEmail();
    },
  },
};
</script>

<style scoped>
.centered-box {
  position: fixed;
  top: 40%;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 500px; /* Optional: Set a maximum width if needed */
  margin-left: auto;
  margin-right: auto;
  transform: translateY(-50%);
}
.box {
  width: 600px;
  height: 500px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 20px;
}

.text-danger {
  color: red;
}

.text-success {
  color: green;
}

.logout-button {
  padding: 10px 20px;
  background-color: blue;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.centered-image {
  display: block;
  margin: 0 auto; /* This will center the image horizontally */
}
</style>
