import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PreLoader = _resolveComponent("PreLoader")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_PreLoader, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass([
        'main-content expanded js-container',
        { collapsed: _ctx.stateStoreInstance.open },
      ])
    }, [
      _createVNode(_component_router_view)
    ], 2)
  ]))
}