<template>
  <div>
    <SubHeader />
    <div class="centered-box">
      <div class="box">
        <div class="card-body p-30">
          <div class="text-center mb-4">
            <h4 class="fs-20 fw-semibold mb-2">Dalasmartlockbox</h4>
            <p class="fs-14 text-body mb-4">
              Thank you for using
              <span class="text-primary">DALA</span> admin-dashbord
            </p>
            <img
              class="rounded-circle mb-3"
              src="../../assets/images/logo-box-2.png"
              alt="DALA-logo"
            />
            <h4 class="fs-18 fw-semibold mb-2">You Are Logged out</h4>
          </div>
          <form>
            <div class="form-group">
              <router-link
                to="/"
                class="default-btn w-100 d-block text-center text-white text-decoration-none"
              >
                Sign In
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubHeader from '../../components/Layouts/SubHeader.vue';

export default {
  name: 'CenteredBox',
  components: { SubHeader },
};
</script>

<style scoped>
.centered-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.box {
  width: 600px; /* Increase width as needed */
  height: 350px; /* Increased height */
  background-color: white; /* Changed background color to white */
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  justify-content: space-between; /* Align elements with space between */
  align-items: center;
  border-radius: 10px;
  padding: 20px; /* Added padding for better spacing */
}

.logout-button {
  padding: 10px 20px;
  background-color: blue;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
</style>
