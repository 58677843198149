<template>
  <header id="darkModeHeader" class="header-area bg-white mb-4">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-7">
          <div class="left-header-content">
            <ul
              class="d-lg-flex d-md-flex align-items-center ps-0 mb-0 list-unstyled"
            >
              <li class="mb-3 mb-md-0 mb-lg-0">
                <div
                  class="d-flex justify-content-between align-items-center cw-273"
                >
                  <router-link to="/" class="flex-shrink-0">
                    <img
                      src="../../assets/images/logo.png"
                      class="main-logo"
                      alt="main-logo"
                    />
                    <img
                      src="../../assets/images/white-logo.png"
                      class="d-none white-logo"
                      alt="white-logo"
                    />
                  </router-link>
                  <div>
                    <button
                      class="border-0 p-0 bg-transparent js-sidebar-expand flex-grow-1 ms-3 text-sm-end menu-bergur"
                      @click="stateStoreInstance.onChange"
                    >
                      <div
                        id="closeBtn"
                        @click="toggleButtonVisibility('openBtn')"
                        v-show="showOpenBtn"
                      >
                        <vue-feather type="menu"></vue-feather>
                      </div>
                      <div
                        id="openBtn"
                        @click="toggleButtonVisibility('closeBtn')"
                        v-show="showCloseBtn"
                      >
                        <vue-feather type="x"></vue-feather>
                      </div>
                    </button>
                  </div>
                </div>
              </li>
              <li class="d-none d-lg-block d-md-none">
                <h3 class="mb-0">Dashboard</h3>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-lg-6 col-md-5">
          <div class="float-lg-end float-md-end">
            <ul
              class="right-header-content list-unstyled d-flex justify-content-lg-end justify-content-md-end justify-content-center align-items-center mb-0 ps-0"
            >
              <li>
                <div class="dropdown date">
                  <button
                    class="btn bg-transparent p-0 border-0 p-0 position-relative badge"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <vue-feather class="t-0" type="calendar"></vue-feather>
                  </button>
                  <div class="dropdown-menu dropdown-lg p-0 rounded-0 border-0">
                    <div class="today-date">
                      <span class="fw-medium">{{ formattedDate }}</span>
                      <vue-feather type="calendar"></vue-feather>
                    </div>
                  </div>
                </div>
              </li>

              <li v-if="userMobile === null">
                <div class="dropdown notifications">
                  <button
                    class="btn bg-transparent border-0 p-0 position-relative"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <vue-feather type="bell"></vue-feather>
                    <span
                      class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                    >
                      1
                    </span>
                  </button>

                  <div
                    class="dropdown-menu dropdown-lg p-0 rounded-10 border-0 box-shadow"
                  >
                    <h6
                      class="dropdown-item-text m-0 py-3 border-bottom d-flex justify-content-between align-items-center"
                    >
                      Notifications
                    </h6>

                    <div class="notification-menu scroll-bar h-400">
                      <router-link
                        to="/verify-mobile"
                        class="dropdown-item py-3"
                      >
                        <small class="float-end text-body ps-2">
                          Authentication
                        </small>
                        <div class="d-flex align-items-center">
                          <div
                            class="avatar-md rounded-circle text-center flex-shrink-0"
                          >
                            <vue-feather type="phone"></vue-feather>
                          </div>
                          <div class="flex-grow-1 ms-2 text-truncate">
                            <h6 class="my-0 fw-medium text-dark fs-14">
                              Mobile Verifications
                            </h6>
                            <small class="text-body mb-0">
                              Complete your registration.
                            </small>
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </li>

              <li>
                <div class="dropdown user-profile">
                  <div
                    class="d-flex align-items-center user-control text-decoration-none"
                    aria-expanded="false"
                  >
                    <div class="flex-shrink-0">
                      <img
                        class="rounded-circle"
                        src="../../assets/images/user/user-1.jpg"
                        alt="user-1"
                      />
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <div
                        class="d-flex align-items-center justify-content-between"
                      >
                        <div class="me-5 d-none d-lg-block" v-if="userData">
                          <h3>
                            {{ userData.first_name }} {{ userData.last_name }}
                          </h3>
                          <span>{{
                            userData.user_type
                              .replace(/_/g, " ")
                              .charAt(0)
                              .toUpperCase() +
                            userData.user_type.replace(/_/g, " ").slice(1)
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from "vue";
import stateStore from "../../utils/store";

import { useAuthStore } from "../../store";
import router from "../../router";

export default defineComponent({
  name: "MainHeader",
  data() {
    return {
      showOpenBtn: true,
      showCloseBtn: false,
      showNotifications: false,
    };
  },
  methods: {
    toggleButtonVisibility(buttonId: string) {
      if (buttonId === "openBtn") {
        this.showOpenBtn = false;
        this.showCloseBtn = true;
        // Perform any other actions you want after clicking "open"
      } else if (buttonId === "closeBtn") {
        this.showOpenBtn = true;
        this.showCloseBtn = false;
        // Perform any other actions you want after clicking "close"
      }
    },
  },
  setup() {
    const stateStoreInstance = stateStore;

    const formattedDate = ref("");

    const authStore = useAuthStore();

    // Use computed property to retrieve user data
    const userData = computed(() => authStore.user);

    const userMobile = computed(() => {
      const user = useAuthStore().getUser;
      if (user && user.mobile_verified_at) {
        return user.mobile_verified_at;
      } else {
        return null;
      }
    });

    const logout = () => {
      authStore.logout(); // Call the logout action
      // Redirect the user to the login page or perform any other desired actions after logout
      router.push("/sign-in");
    };

    onMounted(() => {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const currentDate = new Date();
      formattedDate.value = `${currentDate.getDate()} ${
        months[currentDate.getMonth()]
      } ${currentDate.getFullYear()}`;
    });

    return {
      stateStoreInstance,
      formattedDate,
      logout,
      userData,
      userMobile,
    };
  },
});
</script>
