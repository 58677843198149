<template>
  <div class="container-fluid">
    <div class="inner-main-content">
      <MainHeader />
      <ChangePassword />
      <MainSidebar />
      <MainFooter />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ChangePassword from '../../components/Settings/UpdatePassword/UpdatePassword.vue';
import MainSidebar from '../../components/Layouts/MainSidebar.vue';
import MainHeader from '../../components/Layouts/MainHeader.vue';
import MainFooter from '../../components/Layouts/MainFooter.vue';

export default defineComponent({
  name: 'UpdatePasswordPage',
  components: {
    ChangePassword,
    MainFooter,
    MainSidebar,
    MainHeader,
  },
});
</script>
