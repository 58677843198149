import { defineStore } from 'pinia';
import axios from 'axios';
import Cookies from 'js-cookie';
import router from '../router';

const baseUrl = process.env.VUE_APP_BASE_API_URL;

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    error: null as string | null,
    axiosError: null as any,
    user: JSON.parse(Cookies.get('user') || 'null'),
    token: Cookies.get('token') || null,
    forgetPasswordState: null as string | null,
    resetPasswordState: null as string | null,
    updatePasswordState: null as string | null,
    signInStatus: null as string | null,
  }),
  actions: {
    async signIn(email: string, password: string) {
      try {
        const data = JSON.stringify({ email, password });

        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${baseUrl}/api/v1/login`,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          data,
        };

        const response = await axios.request(config);
        if(response.data.user.email_verified_at!== null){  

          Cookies.set('token', response.data.token);
          Cookies.set('user', JSON.stringify(response.data.user));

        this.setUser(response.data.user);
        this.setToken(response.data.token);
      } else {
        this.setToken(response.data.token);
        this.signInStatus = 'Email Not Verified';
      }
      

        return response.data;
      } catch (error) {
        console.error(error);

        this.setAxiosError(error);

        this.setError('Failed to sign in. Please try again.');

        throw error;
      }
    },
    async logout() {
      try {
        const token = Cookies.get('token');
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${baseUrl}/api/v1/logout`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        await axios.request(config);

        Cookies.remove('token');
        Cookies.remove('user');
        this.user = null;
        this.token = null;

        router.push('/sign-in');
      } catch (error) {
        console.error(error);
      }
    },
    async forgotPassword(email: string) {
      try {
        const data = JSON.stringify({ email });

        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${baseUrl}/api/v1/forgot-password`,
          headers: {
            'Content-Type': 'application/json',
          },
          data,
        };

        await axios.request(config);
        // Handle response if needed
      } catch (error) {
        this.forgetPasswordState = 'invalid';
      }
    },
    async resetPassword(
      token: string,
      email: string,
      password: string,
      passwordConfirmation: string
    ) {
      try {
        const data = JSON.stringify({
          token,
          email,
          password,
          password_confirmation: passwordConfirmation,
        });

        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${baseUrl}/api/v1/reset-password`,
          headers: {
            'Content-Type': 'application/json',
          },
          data,
        };

        await axios.request(config);
        this.resetPasswordState = 'success';
      } catch (error: any) {
        console.error(error);
        this.resetPasswordState = 'Invalid Credentials/Token Expired!';
      }
    },
    async updatePassword(
      id: number,
      currentPassword: string,
      newPassword: string,
      newPasswordConfirmation: string
    ) {
      try {
        const data = JSON.stringify({
          current_password: currentPassword,
          new_password: newPassword,
          new_password_confirmation: newPasswordConfirmation,
        });

        const token = Cookies.get('token');
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${baseUrl}/api/v1/users/${id}/update-password`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          data: data,
        };

        const response = await axios.request(config);
        this.updatePasswordState = 'success';
      } catch (error: any) {
        this.updatePasswordState = 'invalid';
      }
    },

    setError(error: string | null) {
      this.error = error;
    },
    setAxiosError(error: any) {
      this.axiosError = error;
    },
    setUser(user: any) {
      this.user = user;
    },
    setToken(token: string | null) {
      this.token = token;
    },
  },
  getters: {
    getError(): string | null {
      return this.error;
    },
    getUser(): any {
      return this.user;
    },
    getToken(): string | null {
      return this.token;
    },
    isAuthenticated(): boolean {
      return !!this.token; // Returns true if token exists, false otherwise
    },
  },
});
