import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/images/logo.png'
import _imports_1 from '../../assets/images/white-logo.png'


const _hoisted_1 = {
  id: "darkModeHeader",
  class: "header-area bg-white mb-4"
}
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "row align-items-center" }
const _hoisted_4 = { class: "col-lg-6 col-md-7" }
const _hoisted_5 = { class: "left-header-content" }
const _hoisted_6 = { class: "d-lg-flex d-md-flex align-items-center ps-0 mb-0 list-unstyled" }
const _hoisted_7 = { class: "mb-3 mb-md-0 mb-lg-0" }
const _hoisted_8 = { class: "d-flex justify-content-between align-items-center cw-273" }
const _hoisted_9 = { class: "col-lg-6 col-md-5" }
const _hoisted_10 = { class: "float-lg-end float-md-end" }
const _hoisted_11 = { class: "right-header-content list-unstyled d-flex justify-content-lg-end justify-content-md-end justify-content-center align-items-center mb-0 ps-0" }
const _hoisted_12 = { class: "dropdown user-profile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("ul", _hoisted_6, [
              _createElementVNode("li", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_router_link, {
                    to: "/",
                    class: "flex-shrink-0"
                  }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createElementVNode("img", {
                        src: _imports_0,
                        class: "main-logo",
                        alt: "main-logo"
                      }, null, -1),
                      _createElementVNode("img", {
                        src: _imports_1,
                        class: "d-none white-logo",
                        alt: "white-logo"
                      }, null, -1)
                    ])),
                    _: 1
                  })
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("ul", _hoisted_11, [
              _createElementVNode("li", null, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("h6", null, _toDisplayString(_ctx.welcomeMessage), 1)
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}