import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/images/404.png'


const _hoisted_1 = { class: "centered-box" }
const _hoisted_2 = { class: "box" }
const _hoisted_3 = { class: "text-center mb-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("img", {
          src: _imports_0,
          class: "mb-4",
          alt: "starter-img"
        }, null, -1)),
        _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "fs-30 fw-medium mb-1" }, "Page Not Found", -1)),
        _cache[3] || (_cache[3] = _createElementVNode("p", { class: "text-body mb-4" }, " But no worries! Our team is looking ever where while you wait safely. ", -1)),
        _createVNode(_component_router_link, {
          to: "/",
          class: "default-btn text-decoration-none text-white"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode(" Back To Home ")
          ])),
          _: 1
        })
      ])
    ])
  ]))
}