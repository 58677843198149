<template>
  <div class="container-fluid">
    <div class="inner-main-content">
      <MainHeader />
      <UserProfile />
      <MainSidebar />
      <MainFooter />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import UserProfile from '../../components/Profile/UserProfile/UserProfile.vue';
import MainFooter from '../../components/Layouts/MainFooter.vue';
import MainSidebar from '../../components/Layouts/MainSidebar.vue';
import MainHeader from '../../components/Layouts/MainHeader.vue';

export default defineComponent({
  name: 'UserProfilePage',
  components: {
    UserProfile,
    MainFooter,
    MainSidebar,
    MainHeader,
  },
});
</script>
