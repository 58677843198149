<template>
  <div class="centered-box">
    <div class="box">
      <div class="text-center mb-1">
        
        <img
              src="../assets/images/404.png"
              class="mb-4"
              alt="starter-img"
            />
            <h3 class="fs-30 fw-medium mb-1">Page Not Found</h3>
            <p class="text-body mb-4">
              But no worries! Our team is looking ever where while you wait
              safely.
            </p>
            <router-link
              to="/"
              class="default-btn text-decoration-none text-white"
            >
              Back To Home
            </router-link>
          
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ErrorPage",
  components: {

  },
});
</script>