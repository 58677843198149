<template>
  <div class="modal fade" :id="modalId" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirm Deactivation</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p class="text-body fs-14">
            Are you sure you want to deactivate user
            <span style="font-weight: bold; color: red">{{
              selectedUserName
            }}</span
            >? This action will disable their account and revoke their access to
            the platform. Once deactivated, the user will no longer be able to
            log in or access their account. <br /><br />Are you sure you want to
            proceed with deactivation?
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="deleteUser"
            data-bs-dismiss="modal"
          >
            Deactivate
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modalId: String,
    selectedUserName: String,
    selectedUserId: String,
  },
  methods: {
    deleteUser() {
      this.$emit('delete-user', this.selectedUserId);
    },
  },
};
</script>

<style scoped>
/* Add your modal CSS styles here */
</style>
