<template>
  <div class="container-fluid">
    <div class="inner-main-content">
      <MainHeader />
      <MyProfile />
      <MainSidebar />
      <MainFooter />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MyProfile from "../../components/Profile/UserProfile/MyProfile.vue";
import MainFooter from "../../components/Layouts/MainFooter.vue";
import MainSidebar from "../../components/Layouts/MainSidebar.vue";
import MainHeader from "../../components/Layouts/MainHeader.vue";

export default defineComponent({
  name: "MyProfilePage",
  components: {
    MyProfile,
    MainFooter,
    MainSidebar,
    MainHeader,
  },
});
</script>
