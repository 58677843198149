<template>
  <div class="wrapper">
    <div class="card border-0 box-shadow border-radius-10 mb-4">
      <div class="card-body p-4">
        <div
          class="card-title d-flex justify-content-between align-items-center mb-3"
        >
          <h3 class="fw-medium fs-18 mb-0">Locker List</h3>
        </div>
        <div v-if="message" class="alert alert-danger" role="alert">
          {{ message }}
        </div>
        <div
          v-if="unAssignSuccessMessage"
          class="alert alert-success"
          role="alert"
        >
          {{ unAssignSuccessMessage }}
        </div>
        <div
          v-if="unAssignErrorMessage"
          class="alert alert-danger"
          role="alert"
        >
          {{ unAssignErrorMessage }}
        </div>
        <div class="all-table scroll-bar h-660">
          <table class="table align-middle mb-0">
            <thead>
              <tr>
                <th
                  class="text-dark fw-medium pt-0 pb-2 fs-14 ps-0"
                  scope="col"
                >
                  Locker Number
                </th>
                <th class="text-dark fw-medium pt-0 pb-2 fs-14" scope="col">
                  Locker Size
                </th>
                <th class="text-dark fw-medium pt-0 pb-2 fs-14" scope="col">
                  Assigned Homeowner
                </th>
                <th class="text-dark fw-medium pt-0 pb-2 fs-14" scope="col">
                  Status
                </th>
                <th class="text-dark fw-medium pt-0 pb-2 fs-14" scope="col">
                  Actions
                </th>
                <th
                  class="text-dark fw-medium pt-0 pb-2 fs-14"
                  scope="col"
                ></th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="locker in lockers" :key="locker.id">
                <td class="ps-0 d-flex align-items-center">
                  <img
                    class="wh-30 rounded-circle"
                    src="../../assets/images/dala-box/dala-box.png"
                  />
                  <h5 class="fs-14 text-dark mb-0 ms-2 fw-medium">
                    Locker {{ locker.number }}
                  </h5>
                </td>
                <td>
                  <h5 class="fs-14 text-dark mb-0 ms-2 fw-medium">
                    {{ locker.locker_size_id }}
                  </h5>
                </td>
                <td>
                  <h5 class="fs-14 text-dark mb-0 ms-2 fw-medium">
                    <router-link
                      v-if="locker.user_id"
                      :to="`/user-profile/${locker.user_id}`"
                      >{{ locker.user.first_name }}
                      {{ locker.user.last_name }}</router-link
                    >
                    <span v-else>Unassigned</span>
                  </h5>
                </td>
                <td>
                  <h5
                    class="fs-14 text-dark mb-0 ms-2 fw-medium"
                    :class="{
                      'text-primary': getStatus(locker) === 'With Parcel',
                      'text-success': getStatus(locker) === 'Vacant',
                    }"
                  >
                    {{ getStatus(locker) }}
                  </h5>
                </td>
                <td>
                  <span class="text-body">
                    <!-- Check-square icon -->
                    <vue-feather
                      v-if="locker.disabled_at !== null"
                      type="check-square"
                      class="feather-icon"
                      title="Activate Locker"
                      data-bs-toggle="modal"
                      data-bs-target="#EnablelockerModal"
                      @click="
                        getSelectedLockerInfo(
                          locker.id,
                          locker.number,
                          locker.disabled_at,
                          locker.has_current_delivery,
                          locker.user_id
                        )
                      "
                      ><span class="icon-space"></span
                    ></vue-feather>

                    <!-- X-square icon -->
                    <vue-feather
                      v-if="
                        locker.disabled_at === null &&
                        locker.has_current_delivery === 0
                      "
                      type="x-square"
                      class="feather-icon"
                      title="Deactivate Locker"
                      data-bs-toggle="modal"
                      data-bs-target="#DisableLockerModal"
                      @click="
                        getSelectedLockerInfo(
                          locker.id,
                          locker.number,
                          locker.disabled_at,
                          locker.has_current_delivery,
                          locker.user_id
                        )
                      "
                      ><span class="icon-space"></span
                    ></vue-feather>

                    <!-- User-plus icon -->
                    <span class="icon-space"></span>
                    <vue-feather
                      v-if="locker.user_id === null"
                      type="user-plus"
                      class="feather-icon"
                      title="Assign Homeowner"
                      @click="assignHomeOwner(locker.number)"
                      ><span class="icon-space"></span
                    ></vue-feather>

                    <vue-feather
                      v-if="
                        locker.user_id !== null &&
                        locker.has_current_delivery === 0
                      "
                      type="user-minus"
                      class="feather-icon"
                      title="Unassign Homeowner"
                      data-bs-toggle="modal"
                      data-bs-target="#UnlinkUserModal"
                      @click="
                        getSelectedLockerInfo(
                          locker.id,
                          locker.number,
                          locker.disabled_at,
                          locker.has_current_delivery,
                          locker.user_id
                        )
                      "
                    ></vue-feather>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Dectivate Modal -->
    <div
      class="modal fade"
      id="DisableLockerModal"
      tabindex="-1"
      aria-labelledby="DisableLockerModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="DisableLockerModalLabel">
              Disable Locker
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="btnDismissDisableModal"
            ></button>
          </div>
          <div class="modal-body">
            <p class="text-body fs-14">
              Are you sure you want to
              <span style="font-weight: bold; color: red">DISABLE</span> Locker
              {{ selectedLockerNumber }}?
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="disableLocker()"
            >
              Disable Locker
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Enable Modal -->
    <div
      class="modal fade"
      id="EnablelockerModal"
      tabindex="-1"
      aria-labelledby="EnablelockerModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="EnablelockerModalLabel">
              Enable Locker
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="btnDismissEnableModal"
            ></button>
          </div>
          <div class="modal-body">
            <p class="text-body fs-14">
              Are you sure you want to
              <span style="font-weight: bold; color: rgb(13, 126, 60)"
                >ENABLE</span
              >
              Locker {{ selectedLockerNumber }}?
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="enableLocker()"
            >
              Enable Locker
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Unlink User from a Locker Modal -->
    <div
      class="modal fade"
      id="UnlinkUserModal"
      tabindex="-1"
      aria-labelledby="UnlinkUserModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="UnlinkUserModalLabel">
              Unlink User from a Locker
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="btnDismissUnlinkUserModal"
            ></button>
          </div>
          <div class="modal-body">
            <p class="text-body fs-14">
              Are you sure you want to
              <span style="font-weight: bold; color: rgb(13, 126, 60)">
                UNLINK
              </span>
              <span style="font-weight: bold; color: rgb(4, 6, 139)"
                >Unit Owner {{ selectedUserId }}</span
              >
              from
              <span style="font-weight: bold; color: rgb(240, 12, 12)"
                >Locker {{ selectedLockerNumber }}</span
              >?
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-primary" @click="unlinkUser()">
              Unlink User
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useLockersStore } from "@/store/lockers";

export default {
  name: "LockerList",

  data() {
    return {
      lockers: [],
      selectedUserId: "",
      selectedLockerNumber: "",
      selectedLockerID: "",
      selectedLockerStatus: "",
      selectedLockerHasParcel: "",
      unAssignSuccessMessage: "",
      unAssignErrorMessage: "",
      message: "",
    };
  },
  async mounted() {
    await this.fetchLockers();
  },
  methods: {
    async fetchLockers() {
      try {
        const lockersStore = useLockersStore();
        await lockersStore.fetchLockers();
        this.lockers = lockersStore.getLockers;
      } catch (error) {
        console.error("Error fetching lockers:", error);
      }
    },
    getStatus(locker) {
      if (locker.disabled_at !== null) {
        return "Disabled";
      } else if (locker.has_current_delivery === 0) {
        return "Vacant";
      } else {
        return "With Parcel";
      }
    },
    getSelectedLockerInfo(
      lockerId,
      lockerNumber,
      lockerStatus,
      hasdelivery,
      selectedUserId
    ) {
      this.selectedLockerID = lockerId;
      this.selectedLockerNumber = lockerNumber;
      this.selectedLockerStatus = lockerStatus;
      this.selectedLockerHasParcel = hasdelivery;
      this.selectedUserId = selectedUserId;
    },
    async disableLocker() {
      try {
        const lockersStore = useLockersStore();
        if (this.selectedLockerHasParcel == 1) {
          this.message = "Locker with Parcel Cannot Be Disabled.";
          this.clearMessageAfterDelay();
          document.getElementById("btnDismissDisableModal").click();
        } else if (this.selectedLockerStatus == null) {
          await lockersStore.toggleLockerStatus(
            this.selectedLockerID,
            "DISABLE"
          );
          await this.fetchLockers();
          document.getElementById("btnDismissDisableModal").click();
        } else {
          this.message = "Locker already disabled!";
          this.clearMessageAfterDelay();
          document.getElementById("btnDismissDisableModal").click();
        }
      } catch (error) {
        console.error("Error toggling locker status:", error);
      }
    },
    async enableLocker() {
      try {
        const lockersStore = useLockersStore();

        if (this.selectedLockerStatus != null) {
          await lockersStore.toggleLockerStatus(
            this.selectedLockerID,
            "ENABLE"
          );
          await this.fetchLockers();
          document.getElementById("btnDismissEnableModal").click();
        } else {
          this.message = "Locker already enabled!";
          this.clearMessageAfterDelay();
          document.getElementById("btnDismissEnableModal").click();
        }
      } catch (error) {
        console.error("Error toggling locker status:", error);
      }
    },
    assignHomeOwner(lockerId) {
      this.$router.push(`/assign-user-to-locker/${lockerId}`);
    },
    clearMessageAfterDelay() {
      setTimeout(() => {
        this.message = "";
      }, 2000);
    },
    async unlinkUser() {
      try {
        await useLockersStore().unlinkToLocker(
          this.selectedUserId,
          this.selectedLockerID
        );

        if (useLockersStore().unlinkToLockerResponse) {
          this.unAssignSuccessMessage =
            useLockersStore().unlinkToLockerResponse;
          setTimeout(() => {
            this.unAssignSuccessMessage = null;
            useLockersStore().unlinkToLockerResponse = null;
          }, 3000);
        }
        if (useLockersStore().unlinkToLockerError) {
          this.unAssignErrorMessage = useLockersStore().unlinkToLockerError;
          setTimeout(() => {
            this.unAssignErrorMessage = null;
            useLockersStore().unAssignErrorMessage = null;
          }, 2000);
        }
        await this.fetchLockers();
        document.getElementById("btnDismissUnlinkUserModal").click();
      } catch (error) {
        if (error.response && error.response.status === 403) {
          this.unAssignErrorMessage =
            "Cannot Unlink Locker with Parcel Delivery!";
          setTimeout(() => {
            this.unAssignErrorMessage = null;
            useLockersStore().unAssignErrorMessage = null;
          }, 3000);
        }
      }
    },
  },
};
</script>
<style scoped>
.actions-container {
  text-align: left; /* Align the icons to the left */
}

.feather-icon {
  transition: transform 0.3s ease;
}

.feather-icon:hover {
  transform: scale(1.2);
}

.icon-space {
  display: inline-block;
  width: 10px;
}
</style>
