<template>
  <div>
    <SubHeader />
    <div class="content">
      <div class="centered-box">
        <div class="container">
          <div class="box">
            <div class="text-center mb-1">
              <h4 class="fs-20 fw-semibold mb-2">DALA Smart Lockbox</h4>
              <img
                class="rounded-circle mb-3"
                src="../../assets/images/logo-box-2.png"
                alt="DALA-logo"
              />
            </div>
            <div class="text-center mb-4">
              <h4 class="fs-20 fw-semibold mb-2">Sign In</h4>
            </div>
            <div v-if="errorMessage" class="alert alert-danger" role="alert">
              {{ errorMessage }}
            </div>

            <div
              v-if="resendEmailVerificationState"
              class="alert alert-success"
              role="alert"
            >
              A verification email has been sent to your registered email
              address. Please check your inbox and click on the link provided to
              verify your email
            </div>
            <div
              v-if="signInStatus && resendEmailVerificationState === null"
              class="alert alert-danger"
              role="alert"
            >
              Email not verified. If you need to request for email verification
              kindly click:
              <a
                @click="sendEmailVerifications"
                style="cursor: pointer; color: blue; text-decoration: underline"
                >Request for email verification.</a
              >
            </div>
            <form @submit.prevent="signIn" class="signup-form">
              <div class="form-group mb-4">
                <label class="label">Email Address</label>
                <input
                  v-model="email"
                  type="email"
                  class="form-control"
                  placeholder="example@gmail.com"
                />
                <span v-if="!isValidEmail" class="text-danger"
                  >Invalid Email format</span
                >
              </div>
              <div class="form-group mb-4">
                <label class="label">Your Password</label>
                <div class="password-wrapper position-relative">
                  <input
                    v-model="password"
                    type="password"
                    id="password"
                    class="form-control"
                    placeholder="Password"
                  />
                  <span v-if="!isValidPassword" class="text-danger"
                    >Password must be at least 8 characters</span
                  >
                </div>
              </div>
              <div class="form-group mb-4 d-flex justify-content-between">
                <div class="c-form-check form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label
                    class="form-check-label fs-14 text-body ms-0"
                    for="flexCheckDefault"
                  >
                    Remember me
                  </label>
                </div>
                <router-link
                  to="/forget-password"
                  class="text-primary fs-14 text-decoration-none"
                >
                  Forget your password?
                </router-link>
              </div>
              <div class="form-group mb-4">
                <button type="submit" class="default-btn w-100 d-block">
                  Sign In
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SubHeader from "../../components/Layouts/SubHeader.vue";
import { useAuthStore } from "../../store";
import { useRouter } from "vue-router";
import { useMailStore } from "@/store/mail";
import { ref, onMounted, computed } from "vue";
import Cookies from "js-cookie";

const store = useAuthStore();
const router = useRouter();

const email = ref("");
const password = ref("");
let isValidEmail = true;
let isValidPassword = true;
const errorMessage = ref("");
let successMessage = "";

const checkAuth = () => {
  const token = Cookies.get("token");
  if (token) {
    router.push("/dashboard");
  }
};

const validateEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const signIn = async () => {
  isValidEmail = validateEmail(email.value);
  isValidPassword = password.value.length >= 8;

  if (isValidEmail && isValidPassword) {
    try {
      await store.signIn(email.value, password.value);
      //Cookies.set('token', response.token);
      router.push("/dashboard");
      checkAuth();
    } catch (error) {
      errorMessage.value = "Invalid email/password. Please try Again.";
      successMessage = "";

      setTimeout(() => {
        errorMessage.value = "";
      }, 5000);
    }
  } else {
    email.value = "";
    password.value = "";
    errorMessage.value = "Invalid Input";
    successMessage = "";

    setTimeout(() => {
      errorMessage.value = "";
    }, 3000);
  }
};

const sendEmailVerifications = async () => {
  const mailStore = useMailStore();
  await mailStore.resendVerificationEmail();
};

onMounted(() => {
  checkAuth();
});

const signInStatus = computed(() => {
  // Return true/false based on your condition
  return useAuthStore().signInStatus;
});

const resendEmailVerificationState = computed(() => {
  // Return true/false based on your condition
  return useMailStore().resendEmailVerificationState;
});
</script>

<style scoped>
.centered-box {
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 500px; /* Optional: Set a maximum width if needed */
  margin-left: auto;
  margin-right: auto;
  transform: translateY(-50%);
}
.box {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
}

.signup-form {
  width: 100%;
}

.form-group {
  margin-bottom: 20px;
}

.label {
  display: block;
  font-weight: bold;
}

input {
  width: calc(100% - 16px);
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
}
</style>
