import { createPinia } from 'pinia';
import { App } from 'vue';
import { useAuthStore } from './auth'; // Import your existing store module
import { useUsersStore } from './users'; // Import the new users store module
import { useDashboardStore } from './dashboard';
import { useLockersStore } from './lockers';
import { useMailStore } from './mail';
import { useMobileStore } from './mobile';
// Create Pinia instance
const pinia = createPinia();

// Setup function to install Pinia to the app
export function setupStore(app: App<Element>) {
  app.use(pinia);
}

// Export all store modules
export {
  useAuthStore,
  useUsersStore,
  useDashboardStore,
  useLockersStore,
  useMailStore,
  useMobileStore,
};
