<template>
  <div class="row">
    <div class="col-lg-4">
      <div class="card border-0 box-shadow border-radius-10 mb-4">
        <div class="card-body p-4">
          <div
            class="card-title d-flex justify-content-between align-items-center mb-4"
          >
            <h3 class="fw-medium fs-18 mb-0">Settings</h3>
          </div>

          <ul class="ps-0 mb-0 list-unstyled user-menu">
            <li class="mb-1">
              <router-link
                to="/update-password"
                class="fs-14 fw-medium text-body text-decoration-none d-block py-2 px-3"
              >
                Update Password
              </router-link>
            </li>

            <li class="mb-0">
              <router-link
                to="/terms-and-conditions"
                class="fs-14 fw-medium text-body text-decoration-none d-block py-2 px-3"
              >
                Terms and Conditions
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="col-lg-8">
      <div class="card border-0 box-shadow border-radius-10 mb-4">
        <div class="card-body p-4">
          <div class="privacy-content">
            <div
              class="card-title d-flex justify-content-between align-items-center mb-4"
            >
              <h3 class="fw-medium fs-22 mb-0">
                DALA SMARTLOCKBOX Terms and Conditions
              </h3>
            </div>

            <p class="fs-18"><strong>1. Introduction</strong></p>
            <p class="fs-18">
              Welcome to DALA SMARTLOCKBOX, a lockbox service provided by
              Dalasmartlockbox Inc. These terms and conditions govern your use
              of our lockbox service. By using DALA SMARTLOCKBOX, you agree to
              comply with and be bound by these terms and conditions.
            </p>

            <p class="fs-18"><strong>2. Services</strong></p>
            <p class="fs-18">
              DALA SMARTLOCKBOX provides lockbox for secure storage of
              deliveries. Users can pay lockbox for storage of packages. Our
              service includes providing access codes to open and close lockbox
              securely.
            </p>

            <p class="fs-18"><strong>3. User Responsibilities</strong></p>
            <ul class="fs-18">
              <li>
                Users are responsible for ensuring that the information provided
                for lockbox is accurate and up to date.
              </li>
              <li>
                Users are responsible for keeping their access codes secure and
                confidential.
              </li>
              <li>
                Users must comply with all applicable laws and regulations when
                using our lockbox service.
              </li>
            </ul>

            <p class="fs-18"><strong>4. Lockbox Usage</strong></p>
            <ul class="fs-18">
              <li>
                Lockbox are provided on a ownership basis and must be used only
                for storing deliveries.
              </li>
              <li>
                Users must not store any prohibited items in the lockbox,
                including but not limited to hazardous materials, illegal
                substances, perishable items, or items of high value.
              </li>
              <li>Lockbox must be maintain in good condition after use.</li>
            </ul>

            <p class="fs-18"><strong>5. Payment</strong></p>
            <ul class="fs-18">
              <li>
                Users agree to pay necessary fees for ownership of the lockbox.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsAndcCnditions',
};
</script>
