<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-8">
        <div class="card border-0 box-shadow border-radius-10 mb-4">
          <div class="card-body p-4">
            <div
              class="card-title d-flex justify-content-between align-items-center mb-3"
            >
              <h3 class="fw-medium fs-18 mb-0">Add User</h3>
            </div>

            <div v-if="SuccessAddingStatus" class="mt-3">
              <div class="alert alert-success" role="alert">
                New User Added Successfully!
              </div>
            </div>

            <div v-if="AddUserError" class="mt-3">
              <div class="alert alert-danger" role="alert">
                <ul class="error-list">
                  <li
                    v-for="(errorMessages, fieldName) in AddUserError"
                    :key="fieldName"
                  >
                    {{ errorMessages[0] }}
                  </li>
                </ul>
              </div>
            </div>

            <form @submit.prevent="submitForm" ref="userForm">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group mb-4">
                    <label class="label">First Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter first name"
                      v-model="firstName"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group mb-4">
                    <label class="label">Last Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter last name"
                      v-model="lastName"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group mb-4">
                    <label class="label">Email Address</label>
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Enter email address"
                      v-model="email"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group mb-4">
                    <label class="label">Phone</label>
                    <input
                      type="tel"
                      class="form-control"
                      placeholder="Enter phone number"
                      v-model="mobile"
                      required
                    />
                    <div v-if="!isValidMobile" class="text-danger">
                      Phone number must be 11 digits
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group mb-4">
                    <label class="label">Display Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter display name"
                      v-model="displayName"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group mb-4">
                    <label class="label">House Number</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter house number"
                      v-model="houseNumber"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group mb-4">
                    <label class="label">Address</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter your address"
                      v-model="address"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group mb-4">
                    <label class="label">Country</label>
                    <select
                      class="form-select form-control"
                      aria-label="Default select example"
                      v-model="country"
                      required
                    >
                      <option value="">Select Country</option>
                      <option value="Philippines">Philippines</option>
                      <!-- Add other countries as options -->
                    </select>
                  </div>
                </div>
              </div>
              <button type="submit" class="default-btn">Add New User</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useUsersStore } from '@/store/users';
import { useRouter } from 'vue-router';

export default {
  name: 'AddUser',
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      displayName: '',
      houseNumber: '',
      address: '',
      country: '',
      isValidMobile: true,
    };
  },
  computed: {
    AddUserError() {
      return useUsersStore().AddUserError;
    },
    SuccessAddingStatus() {
      return useUsersStore().SuccessAddingStatus;
    },
  },
  methods: {
    async submitForm() {
      try {
        const store = useUsersStore();
        const userData = new FormData();

        userData.append('first_name', this.firstName);
        userData.append('last_name', this.lastName);
        userData.append('email', this.email);
        userData.append('mobile', this.mobile);
        userData.append('display_name', this.displayName);
        userData.append('address_house_number', this.houseNumber);
        userData.append('address', this.address);
        userData.append('country', this.country);

        await store.addUser(userData);

        if (this.SuccessAddingStatus) {
          setTimeout(() => {
            useUsersStore().SuccessAddingStatus = null;
          }, 3000);
        }

        if (this.AddUserError) {
          setTimeout(() => {
            useUsersStore().AddUserError = null;
          }, 3000);
        }
        // Clear form fields after successful submission if needed
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.mobile = '';
        this.displayName = '';
        this.houseNumber = '';
        this.address = '';
        this.country = '';
        this.isValidMobile = true;
      } catch (error) {
        console.error('Server response error:', error);
      }
    },
  },
};
</script>

<style scoped>
/* Your component-specific styles */
.error-list {
  margin-bottom: 0; /* Remove bottom margin */
}

.error-list li {
  padding-bottom: 0; /* Remove bottom padding */
}
</style>

<style scoped>
/* Your component-specific styles */
.error-list {
  margin-bottom: 0; /* Remove bottom margin */
}

.error-list li {
  padding-bottom: 0; /* Remove bottom padding */
}
</style>
