<template>
  <div class="centered-box">
    <div class="d-table">
      <div class="d-table-cell">
        <div
          class="card border-0 box-shadow border-radius-10 mb-4 w-100"
          style="max-width: 500px; margin: auto"
        >
          <div class="card-body p-30">
            <img
              class="rounded-circle mb-3 centered-image"
              src="../../../assets/images/logo-box-2.png"
              alt="DALA-logo"
            />

            <div class="text-center mb-4">
              <h4 class="fs-20 fw-semibold mb-2">Reset Password</h4>
              <p class="fs-14 text-body">Type your new password</p>
            </div>

            <div v-if="resetMessage" class="mt-3">
              <div class="alert alert-danger" role="alert">
                {{ resetMessage }}
              </div>
            </div>
            <div v-if="errorMessage" class="mt-3">
              <div class="alert alert-danger" role="alert">
                {{ errorMessage }}
              </div>
            </div>
            <div v-if="successMessage" class="mt-3">
              <div class="alert alert-success" role="alert">
                Password Reset Success! Please Signin
              </div>
            </div>
            <form @submit.prevent="submitForm" v-if="!successMessage">
              <div class="form-group mb-4">
                <label class="label">Your Password</label>
                <div class="password-wrapper position-relative">
                  <input
                    :type="showPassword ? 'text' : 'password'"
                    id="password"
                    class="form-control"
                    placeholder="Your New Password"
                    v-model="password"
                  />
                  <i
                    @click="showPassword = !showPassword"
                    :class="[
                      'ri-eye-',
                      showPassword ? 'off' : 'on',
                      '-line password-toggle-icon translate-middle-y top-50 end-0 position-absolute',
                    ]"
                    aria-hidden="true"
                  ></i>
                </div>
                <div
                  v-if="submitted && (password === '' || password.length < 8)"
                  class="text-danger"
                >
                  Password should be at least 8 characters long.
                </div>
              </div>
              <div class="form-group mb-4">
                <label class="label">Confirm Password</label>
                <div class="password-wrapper position-relative">
                  <input
                    :type="showConfirmPassword ? 'text' : 'password'"
                    id="confirmPassword"
                    class="form-control"
                    placeholder="Confirm your New Password"
                    v-model="confirmPassword"
                  />
                </div>
                <div
                  v-if="
                    submitted &&
                    (confirmPassword === '' || password !== confirmPassword)
                  "
                  class="text-danger"
                >
                  Passwords do not match.
                </div>
              </div>

              <div class="form-group mb-4">
                <button type="submit" class="default-btn w-100 d-block">
                  Set Password
                </button>
              </div>
            </form>
            <div class="form-group mb-4" v-if="successMessage">
              <button
                @click="redirectohome()"
                type="button"
                class="default-btn w-100 d-block"
              >
                Go to Sign-in
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from '../../../store/auth';
import router from '../../../router';

export default {
  name: 'ResetPassword',
  data() {
    return {
      password: '',
      confirmPassword: '',
      showPassword: false,
      showConfirmPassword: false,
      resetMessage: '',
      successMessage: '',
      submitted: false,
      errorMessage: '',
    };
  },

  computed: {
    resetPasswordState() {
      return useAuthStore().resetPasswordState;
    },
  },

  methods: {
    async submitForm() {
      this.submitted = true;

      try {
        const authStore = useAuthStore();
        const payload = this.extractToken();
        const token = payload.split('?')[0];
        const queryParams = new URLSearchParams(window.location.search);
        const email = queryParams.get('email');

        if (this.password === '' || this.confirmPassword === '') {
          this.resetMessage = 'Please fill in all fields.';
        } else if (this.password !== this.confirmPassword) {
          this.resetMessage = 'Passwords do not match.';
        } else if (this.password.length < 8) {
          this.resetMessage = 'Password should be at least 8 characters long.';
        } else {
          await authStore.resetPassword(
            token,
            email,
            this.password,
            this.confirmPassword
          );
          if (this.resetPasswordState === 'success') {
            this.successMessage = true;
          } else {
            this.successMessage = false;
            this.errorMessage = this.resetPasswordState;
          }
        }
        setTimeout(() => {
          this.resetMessage = '';
        }, 3000);
      } catch (error) {
        console.error('Error verifying email:', error);
        this.resetMessage = 'An error occurred while resetting the password.';
      }
    },
    extractToken() {
      const url = window.location.href;
      const parts = url.split('/');
      const payload = parts.slice(4).join('/');
      return payload;
    },
    redirectohome() {
      router.push('/sign-in');
    },
  },
};
</script>

<style>
.centered-box {
  position: fixed;
  top: 40%;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  transform: translateY(-50%);
}
.alert-container {
  margin-top: 10px; /* Adjust as needed */
}
.centered-image {
  display: block;
  margin: 0 auto; /* This will center the image horizontally */
}
</style>
