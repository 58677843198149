import { defineStore } from 'pinia';
import axios from 'axios';
import { useAuthStore } from './auth';

const baseUrl = process.env.VUE_APP_BASE_API_URL;

export const useMobileStore = defineStore({
  id: 'mobile',
  state: () => ({
    verificationResponse: null as any,
    otpVerificationResponse: null as any,
    otpId: '' as any,
  }),
  actions: {
    async requestMobileVerify() {
     
      try {
        const authStore = useAuthStore();
        const response = await axios.post(
          `${baseUrl}/api/v1/request-mobile-verification`,
          {},
          {
            headers: {
              Authorization: `Bearer ${authStore.getToken}`,
            },
          }
        );
        this.verificationResponse = response.data.message;
        this.otpId = response.data.otp.id;
      } catch (error) {
        console.error(error);
      }
    },
    async verifyOTP(otp: string) {
      const authStore = useAuthStore();
      try {
        const response = await axios.post(
          `${baseUrl}/api/v1/otps/${this.otpId}/verify`,
          { pin: otp },
          {
            headers: {
              Authorization: `Bearer ${authStore.getToken}`,
            },
          }
        );
        this.otpVerificationResponse = response.data;
      } catch (error: any) {
        if (error.response && error.response.data && error.response.data.status) {
          this.otpVerificationResponse = error.response.data.status;
        } else {
          this.otpVerificationResponse = String('invalid');
        }
      }
    },
  },
  getters: {},
});
