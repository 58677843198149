<template>
  <div class="card border-0 box-shadow border-radius-10 mb-4">
    <div class="card-body px-4 py-5">
      <div class="text-center">
        <img
          src="../../assets/images/starter-img.png"
          class="mb-4"
          alt="starter-img"
        />
        <h3 class="fs-30 fw-medium mb-4">
          Excuse the Dust! This Page is Getting a Makeover.
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SoonContent',
};
</script>
