<template>
  <div class="flex-grow-1"></div>
  <footer class="footer-area card border-0 box-shadow rounded-0">
    <div class="footer-content text-center card-body p-4">
      <p class="fs-14">
        © <span class="text-primary">Dalasmartlockbox-admin</span> is Proudly
        Owned by Dalasmartlockbox Inc.
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'MainFooter',
};
</script>
