<template>
    <div class="container-fluid">
      <div class="inner-main-content">
        <MainHeader />
        <ResendEmailVerification />
        <MainSidebar />
        <MainFooter />
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent } from 'vue';
  import ResendEmailVerification from '../../components/Settings/ResendEmailVerifications/ResendEmailVerifications.vue';
  import MainSidebar from '../../components/Layouts/MainSidebar.vue';
  import MainHeader from '../../components/Layouts/MainHeader.vue';
  import MainFooter from '../../components/Layouts/MainFooter.vue';
  
  export default defineComponent({
    name: 'UpdatePasswordPage',
    components: {
        ResendEmailVerification,
      MainFooter,
      MainSidebar,
      MainHeader,
    },
  });
  </script>
  