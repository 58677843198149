<template>
  <div class="container-fluid">
    <div class="inner-main-content">
      <MainHeader />
      <MobileVerification />
      <MainSidebar />
      <MainFooter />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MobileVerification from "../../components/Settings/MobileVerification/MobileVerification.vue";
import MainSidebar from "../../components/Layouts/MainSidebar.vue";
import MainHeader from "../../components/Layouts/MainHeader.vue";
import MainFooter from "../../components/Layouts/MainFooter.vue";

export default defineComponent({
  name: "UpdatePasswordPage",
  components: {
    MobileVerification,
    MainFooter,
    MainSidebar,
    MainHeader,
  },
});
</script>
