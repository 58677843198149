<template>
  <div class="centered-box">
    <div class="d-table">
      <div class="d-table-cell">
        <div
          class="card border-0 box-shadow border-radius-10 mb-4 w-100"
          style="max-width: 500px; margin: auto"
        >
          <div class="card-body p-30">
            <div class="text-center mb-4">
              <h4 class="fs-20 fw-semibold mb-2">Forgot Your Password</h4>
              <p class="fs-14 text-body">
                Enter the email address you used when you joined and we’ll send
                you instructions to reset your password
              </p>
            </div>
            <div v-if="showAlert" class="mt-3">
              <div class="alert alert-success" role="alert">
                Reset Password Sent! Please check your inbox (and spam folder)
                for the reset instructions.
              </div>
            </div>
            <form @submit.prevent="handleSubmit" v-if="!showAlert">
              <div class="form-group mb-4">
                <label class="label">Email Address</label>
                <input
                  v-model.trim="email"
                  type="email"
                  class="form-control"
                  placeholder="Enter email address"
                  required
                />
              </div>
              <div class="form-group">
                <button type="submit" class="default-btn w-100 d-block">
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useAuthStore } from '../../../store/auth'; // Update the path as needed

export default defineComponent({
  name: 'ForgetPassword',
  data() {
    return {
      showAlert: false,
      email: '',
    };
  },
  methods: {
    async handleSubmit() {
      try {
        // Call the forgotPassword action
        await useAuthStore().forgotPassword(this.email);
        // Show success alert
        this.showAlert = true;
      } catch (error) {
        console.error(error);
        // Handle error if needed
      }
    },
  },
});
</script>

<style scoped>
/* Your existing styles */
</style>

<style scoped>
.centered-box {
  position: fixed;
  top: 60%;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 500px; /* Optional: Set a maximum width if needed */
  margin-left: auto;
  margin-right: auto;
  transform: translateY(-50%);
}
.default-btn {
  width: 90%;
  max-width: 500px; /* Optional: Set a maximum width if needed */
}
.box {
  width: 500px;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
}

.signup-form {
  width: 100%;
}

.form-group {
  margin-bottom: 20px;
}

.label {
  display: block;
  font-weight: bold;
}

input {
  width: calc(100% - 16px);
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
}
</style>
