import {
  createWebHistory,
  createRouter,
  RouterOptions,
  RouteRecordRaw,
} from "vue-router";

import { useAuthStore } from "../store"; // Importing the auth store to check authentication status
import ModalsPage from "../pages/Components/ModalsPage.vue";
import DashboardPage from "../pages/Dashboard/DashboardPage.vue";
import UserProfilePage from "../pages/Profile/UserProfilePage.vue";
import SettingPage from "../pages/Social/SettingPage.vue";
import TestPage from "../pages/TestPage.vue";
import StarterPage from "../pages/StarterPage.vue";
import SoonPage from "../pages/SoonPage.vue";
import NotificationsPage from "../pages/NotificationsPage.vue";
import TransactionsPage from "../pages/History/MyDeliveriesPage.vue";
import UsersListPage from "../pages/Users/UsersListPage.vue";
import AddUserPage from "../pages/Users/AddUserPage.vue";
import UpdateUserPage from "../pages/Users/UpdateUserPage.vue";
import MyProfilePage from "../pages/Profile/MyProfilePage.vue";
import SignInPage from "../pages/Authentication/SignInPage.vue";
import SignUpPage from "../pages/Authentication/SignUpPage.vue";
import ResetPasswordPage from "../pages/Authentication/ResetPasswordPage.vue";
import ForgetPasswordPage from "../pages/Authentication/ForgetPasswordPage.vue";
import LogOutPage from "../pages/Authentication/LogOutPage.vue";
import ConfirmEmailPage from "../pages/Authentication/ConfirmEmailPage.vue";
import UpdatePasswordPage from "../pages/Setting/UpdatePasswordPage.vue";
import LockerList from "../pages/Lockers/LockersListPage.vue";
import MyLockerList from "../pages/Lockers/MyLockerListPage.vue";
import ErrorPage from "../pages/ErrorPage.vue";
import AssignUserToLocker from "../pages/Lockers/AssignUserToLocker.vue";
import TermsAndConditionPage from "../pages/Setting/TermsAndConditionsPage.vue";
import MobileVerificationPage from "../pages/Setting/MobileVerificationPage.vue";
import ResendEmailVerifications from "../pages/Setting/ResendEmailVerificationsPage.vue";
import RedirectLanding from "../components/RedirectLanding.vue"; // Your redirect component

const routes: RouteRecordRaw[] = [
  {
    path: "/modals",
    name: "ModalsPage",
    component: ModalsPage,
  },
  {
    path: "/welcome",
    name: "WelcomePage",
    component: () => {
      window.location.href = "/welcome"; // Direct to the static HTML in the public folder
    },
  },
  {
    path: "/",
    name: "home",
    redirect: { name: "WelcomePage" },
  },
  {
    path: "/test",
    name: "test",
    component: TestPage,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/locker-list",
    name: "lockerlist",
    component: LockerList,
    meta: { requiresAuth: true, allowedRoles: ["ADMIN"] },
  },
  {
    path: "/my-lockerlist",
    name: "mylockerlist",
    component: MyLockerList,
    meta: { requiresAuth: true, allowedRoles: ["HOME_OWNER"] },
  },
  {
    path: "/assign-user-to-locker/:id",
    name: "assignusertolocker",
    component: AssignUserToLocker,
    meta: { requiresAuth: true, allowedRoles: ["ADMIN"] },
  },
  {
    path: "/my-deliveries",
    name: "mydeliveries",
    component: TransactionsPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/user-profile/:id",
    name: "UserProfilePage",
    component: UserProfilePage,
    meta: { requiresAuth: true },
  },
  {
    path: "/update-user/:id",
    name: "UpdateUserPage",
    component: UpdateUserPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/terms-and-conditions",
    name: "termsandconditions",
    component: TermsAndConditionPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/setting",
    name: "SettingPage",
    component: SettingPage,
    meta: { requiresAuth: true, allowedRoles: ["ADMIN"] },
  },
  {
    path: "/starter",
    name: "StarterPage",
    component: StarterPage,
  },
  {
    path: "/underconstruction",
    name: "SoonPage",
    component: SoonPage,
  },
  {
    path: "/notifications",
    name: "NotificationsPage",
    component: NotificationsPage,
    meta: { requiresAuth: true, allowedRoles: ["ADMIN"] },
  },
  {
    path: "/user-list",
    name: "UsersListPage",
    component: UsersListPage,
    meta: { requiresAuth: true, allowedRoles: ["ADMIN"] },
  },
  {
    path: "/add-user",
    name: "AddUserPage",
    component: AddUserPage,
    meta: { requiresAuth: true, allowedRoles: ["ADMIN"] },
  },
  {
    path: "/my-profile",
    name: "myProfilePage",
    component: MyProfilePage,
    meta: { requiresAuth: true, allowedRoles: ["HOME_OWNER", "ADMIN"] },
  },
  {
    path: "/sign-in",
    name: "SignInPage",
    component: SignInPage,
  },
  {
    path: "/sign-up",
    name: "SignUpPage",
    component: SignUpPage,
  },
  {
    path: "/passwords/:token",
    name: "ResetPasswordPage",
    component: ResetPasswordPage,
  },
  {
    path: "/forget-password",
    name: "ForgetPasswordPage",
    component: ForgetPasswordPage,
  },
  {
    path: "/log-out",
    name: "LogOutPage",
    component: LogOutPage,
  },
  {
    path: "/confirm-mail/:token(.*)",
    name: "confirmmail",
    component: ConfirmEmailPage,
  },
  {
    path: "/verify-email",
    name: "verifymail",
    component: ResendEmailVerifications,
  },
  {
    path: "/verify-mobile",
    name: "mobileverifications",
    component: MobileVerificationPage,
  },
  {
    path: "/update-password",
    name: "UpdatePasswordPage",
    component: UpdatePasswordPage,
    meta: { requiresAuth: true }, // Guarded route
  },
];

routes.push({
  path: "/:catchAll(.*)",
  component: ErrorPage,
});

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes: routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
} as RouterOptions);

// Navigation guard to check authentication status and role before navigating to guarded routes
router.beforeEach((to, from, next) => {
  const authStore = useAuthStore(); // Accessing the auth store
  const isAuthenticated = !!authStore.getUser; // Checking if the user is authenticated

  // If the route requires authentication and user is not authenticated, redirect to login page
  if (to.meta.requiresAuth && !isAuthenticated) {
    next({ name: "SignInPage" });
  } else if (
    to.meta.allowedRoles &&
    !(to.meta.allowedRoles as string[]).includes(authStore.getUser.user_type)
  ) {
    // Explicitly cast to string[] and then check for inclusion
    next({ name: "ErrorPage", params: { code: 403 } });
  } else {
    next(); // Proceed to the next route
  }
});

export default router;
