<template>
  <header id="darkModeHeader" class="header-area bg-white mb-4">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-7">
          <div class="left-header-content">
            <ul
              class="d-lg-flex d-md-flex align-items-center ps-0 mb-0 list-unstyled"
            >
              <li class="mb-3 mb-md-0 mb-lg-0">
                <div
                  class="d-flex justify-content-between align-items-center cw-273"
                >
                  <router-link to="/" class="flex-shrink-0">
                    <img
                      src="../../assets/images/logo.png"
                      class="main-logo"
                      alt="main-logo"
                    />
                    <img
                      src="../../assets/images/white-logo.png"
                      class="d-none white-logo"
                      alt="white-logo"
                    />
                  </router-link>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-lg-6 col-md-5">
          <div class="float-lg-end float-md-end">
            <ul
              class="right-header-content list-unstyled d-flex justify-content-lg-end justify-content-md-end justify-content-center align-items-center mb-0 ps-0"
            >
              <li>
                <div class="dropdown user-profile">
                  <h6>{{ welcomeMessage }}</h6>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
  name: 'SubHeader',

  setup() {
    const welcomeMessage = ref('');

    onMounted(() => {
      const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];

      const currentDate = new Date();
      const formattedDate = `${currentDate.getDate()} ${
        months[currentDate.getMonth()]
      } ${currentDate.getFullYear()}`;

      const formattedTime = `${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;

      welcomeMessage.value = `Welcome! Today is ${formattedDate} ${formattedTime}`;
    });

    return {
      welcomeMessage,
    };
  },
});
</script>

<style scoped>
h5 {
  font-family: 'Arial', sans-serif; /* Change font family */
}
</style>
