<template>
  <div class="row">
    <div class="col-lg-8">
      <div class="card border-0 box-shadow border-radius-10 mb-4">
        <div class="card-body p-0">
          <div class="position-relative">
            <img
              class="w-100 h-400"
              src="../../../assets/images/profile-bg.jpg"
              alt="ad-user-bg-two"
            />
          </div>

          <div class="d-flex justify-content-between align-items-center p-4">
            <div class="users-profile top-125 d-flex align-items-end ms-0">
              <div class="position-relative">
                <img
                  class="w-100 h-400"
                  src="../../../assets/images/publicimages/default-profile.png"
                  alt="User Profile Picture"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="tab-content" id="myTabContent">
    <div class="row">
      <div class="col-lg-8">
        <div class="card border-0 box-shadow border-radius-10 mb-4">
          <div class="card-body p-4">
            <div
              class="card-title d-flex justify-content-between align-items-left mb-3"
            >
              <h3 class="fw-medium fs-18 mb-0">Profile</h3>
            </div>
            <div class="alert alert-danger" v-if="userEmail === null">
              Please conmplete your registration by verifying your email.
            </div>
            <div class="alert alert-danger" v-if="userMobile === null">
              Please conmplete your registration by verifying your Mobile.
            </div>
            <form>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group mb-4">
                    <label class="label">First Name</label>
                    <input
                      type="text"
                      class="form-control"
                      :value="userData ? userData.first_name : ''"
                      @input="updateFirstName($event.target.value)"
                      :readonly="true"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group mb-4">
                    <label class="label">Last Name</label>
                    <input
                      type="text"
                      class="form-control"
                      :value="userData ? userData.last_name : ''"
                      @input="updateLastName($event.target.value)"
                      :readonly="true"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group mb-4">
                    <label class="label">Address</label>
                    <input
                      type="text"
                      class="form-control"
                      :value="userData ? userData.address : ''"
                      @input="updateAddress($event.target.value)"
                      :readonly="true"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group mb-4">
                    <label class="label">Email Address</label>
                    <input
                      type="text"
                      class="form-control"
                      :value="userData ? userData.email : ''"
                      @input="updateEmail($event.target.value)"
                      :readonly="true"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group mb-4">
                    <label class="label">Phone</label>
                    <input
                      type="text"
                      class="form-control"
                      :value="userData ? userData.mobile : ''"
                      @input="updatePhone($event.target.value)"
                      :readonly="true"
                    />
                  </div>
                </div>
                <!-- Add more fields as needed -->
                <div class="d-flex justify-content-start mt-4">
                  <router-link
                    v-if="userData"
                    :to="`/update-user/${userData.id}`"
                  >
                    <button class="default-btn">Edit Profile</button>
                  </router-link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { useAuthStore } from "../../../store/auth"; // Import the auth store

export default {
  name: "UserProfile",
  setup() {
    const userStore = useAuthStore();
    const userData = ref(null); // Reactive reference to hold user data

    // Fetch user data on component mount
    onMounted(async () => {
      userData.value = userStore.user;
    });

    const userImage = computed(() => {
      // Check if userData is available
      if (userData.value && userData.value.img) {
        // Return user image if available
        return userData.value.img;
      } else {
        // Return default image if userData or user image is not availablec

        return require("../../../assets/images/publicimages/default-profile.png");
      }
    });

    // Method to update first name
    const updateFirstName = (value) => {
      if (userData.value) {
        userData.value.firstName = value;
      }
    };

    // Method to update last name
    const updateLastName = (value) => {
      if (userData.value) {
        userData.value.lastName = value;
      }
    };

    // Method to update address
    const updateAddress = (value) => {
      if (userData.value) {
        userData.value.address = value;
      }
    };

    // Method to update email
    const updateEmail = (value) => {
      if (userData.value) {
        userData.value.email = value;
      }
    };

    // Method to update phone
    const updatePhone = (value) => {
      if (userData.value) {
        userData.value.phone = value;
      }
    };

    const userEmail = computed(() => {
      const user = userStore.getUser;
      return user && user.email_verified_at ? user.email_verified_at : null;
    });

    // Computed property to check if user mobile is verified
    const userMobile = computed(() => {
      const user = userStore.getUser;
      return user && user.mobile_verified_at ? user.mobile_verified_at : null;
    });

    return {
      userData,
      userImage,
      updateFirstName,
      updateLastName,
      updateAddress,
      updateEmail,
      updatePhone,
      userEmail,
      userMobile,
    };
  },
};
</script>

<style scoped>
.styled-hr {
  border-top: 2px solid #ccc; /* Color of the horizontal line */
  margin-top: 20px; /* Adjust the top margin as needed */
  margin-bottom: 20px; /* Adjust the bottom margin as needed */
}
</style>
