<template>
  <div id="SidebarLightMode" class="sidebar-area darkmode-sidebar-area">
    <div
      id=""
      class="layout-menu menu-vertical menu bg-menu-theme scroll-bar active hv-100"
    >
      <div class="menu-inner-shadow"></div>

      <ul class="menu-inner">
        <!-- Dashboard -->
        <li class="menu-item">
          <a
            href="javascript:void(0);"
            class="menu-link menu-toggle"
            data-bs-toggle="collapse"
            data-bs-target="#collapse1"
            aria-expanded="true"
            aria-controls="collapse1"
          >
            <vue-feather type="grid" class="menu-icon tf-icons"></vue-feather>
            <span class="title">Dashboard</span>
          </a>

          <ul
            id="collapse1"
            class="menu-sub collapse show"
            data-bs-parent="#accordionExample"
          >
            <li class="menu-item">
              <router-link to="/dashboard" class="menu-link">
                <vue-feather
                  type="bar-chart-2"
                  class="menu-icon tf-icons"
                ></vue-feather
                >Analytics
              </router-link>
            </li>
          </ul>
        </li>

        <!-- Users -->
        <li class="menu-item" v-if="userRole === 'ADMIN'">
          <a
            href="javascript:void(0);"
            class="menu-link menu-toggle collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#collapse8"
            aria-expanded="false"
            aria-controls="collapse8"
          >
            <vue-feather type="users" class="menu-icon tf-icons"></vue-feather>
            <span class="title">Users</span>
          </a>
          <ul
            id="collapse8"
            class="menu-sub collapse"
            data-bs-parent="#accordionExample"
          >
            <li class="menu-item">
              <router-link to="/user-list" class="menu-link">
                User List
              </router-link>
            </li>
            <li class="menu-item">
              <router-link to="/add-user" class="menu-link">
                Add User
              </router-link>
            </li>
          </ul>
        </li>

        <!-- Profile -->
        <li class="menu-item">
          <a
            href="javascript:void(0);"
            class="menu-link menu-toggle collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#collapse9"
            aria-expanded="false"
            aria-controls="collapse9"
          >
            <vue-feather type="user" class="menu-icon tf-icons"></vue-feather>
            <span class="title">Profile</span>
          </a>
          <ul
            id="collapse9"
            class="menu-sub collapse"
            data-bs-parent="#accordionExample"
          >
            <li class="menu-item">
              <router-link to="/my-profile" class="menu-link">
                My Profile
              </router-link>
            </li>
          </ul>
        </li>

        <!-- Modules -->
        <li class="menu-header small text-uppercase">
          <span class="menu-header-text fs-14 text-white">Modules</span>
        </li>
        <li class="menu-item" v-if="userRole === 'ADMIN'">
          <a
            href="javascript:void(0);"
            class="menu-link menu-toggle collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#collapse13"
            aria-expanded="false"
            aria-controls="collapse13"
          >
            <vue-feather type="table" class="menu-icon tf-icons"></vue-feather>
            <span class="title">Locker Management</span>
          </a>
          <ul
            id="collapse13"
            class="menu-sub collapse"
            data-bs-parent="#accordionExample"
          >
            <li class="menu-item">
              <router-link to="/locker-list" class="menu-link">
                Locker List
              </router-link>
            </li>
          </ul>
        </li>

        <!-- Transactions -->
        <li class="menu-item">
          <router-link
            to="/my-deliveries"
            class="menu-link"
            v-if="userRole === 'HOME_OWNER'"
          >
            <vue-feather
              type="shopping-bag"
              class="menu-icon tf-icons"
            ></vue-feather>
            <span class="title">My Delivery</span>
          </router-link>
        </li>

        <!-- Transactions -->
        <li class="menu-item">
          <router-link
            to="/my-lockerlist"
            class="menu-link"
            v-if="userRole === 'HOME_OWNER'"
          >
            <vue-feather
              type="archive"
              class="menu-icon tf-icons"
            ></vue-feather>
            <span class="title">My Lockers</span>
          </router-link>
        </li>

        <!-- Account -->

        <li class="menu-item">
          <a
            href="javascript:void(0);"
            class="menu-link menu-toggle collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#collapse14"
            aria-expanded="false"
            aria-controls="collapse14"
          >
            <vue-feather
              type="settings"
              class="menu-icon tf-icons"
            ></vue-feather>
            <span class="title">Settings</span>
          </a>
          <ul
            id="collapse14"
            class="menu-sub collapse"
            data-bs-parent="#accordionExample"
          >
            <li class="menu-item">
              <router-link to="/update-password" class="menu-link">
                Update Password
              </router-link>
            </li>

            <li class="menu-item" v-if="userEmail === null">
              <router-link to="/verify-email" class="menu-link">
                Verify Email
              </router-link>
            </li>
            <li class="menu-item" v-if="userMobile === null">
              <router-link to="/verify-mobile" class="menu-link">
                Verify Mobile
              </router-link>
            </li>
            <li class="menu-item">
              <router-link to="/terms-and-conditions" class="menu-link">
                Terms and Conditions
              </router-link>
            </li>
          </ul>
        </li>

        <li class="menu-header small text-uppercase">
          <span class="menu-header-text fs-14 text-white">Account</span>
        </li>
        <li class="menu-item pb-85">
          <a @click="logout" href="#" class="menu-link">
            <vue-feather type="power" class="menu-icon tf-icons"></vue-feather>
            <span class="title">Logout</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from "../../store";

export default {
  name: "MainSidebar",

  computed: {
    userRole() {
      const user = useAuthStore().getUser;
      // Check if user exists and has a role property
      if (user && user.user_type) {
        return user.user_type;
      } else {
        return null;
      }
    },
    userEmail() {
      const user = useAuthStore().getUser;
      if (user && user.email_verified_at) {
        return user.email_verified_at;
      } else {
        return null;
      }
    },
    userMobile() {
      const user = useAuthStore().getUser;
      if (user && user.mobile_verified_at) {
        return user.mobile_verified_at;
      } else {
        return null;
      }
    },
  },
  setup() {
    const authStore = useAuthStore();

    const logout = () => {
      authStore.logout(); // Call the logout action from the auth store
      // Redirect the user to the login page or perform any other desired actions after logout
      // Example: router.push('/login');
    };

    return {
      logout,
    };
  },
};
</script>
