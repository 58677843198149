<template>
  <div class="row">
    <div class="col-lg-4">
      <div class="card border-0 box-shadow border-radius-10 mb-4">
        <div class="card-body p-4">
          <div
            class="card-title d-flex justify-content-between align-items-center mb-4"
          >
            <h3 class="fw-medium fs-18 mb-0">Settings</h3>
          </div>

          <ul class="ps-0 mb-0 list-unstyled user-menu">
            <li class="mb-1">
              <router-link
                to="/update-password"
                class="fs-14 fw-medium text-body text-decoration-none d-block py-2 px-3"
              >
                Update Password
              </router-link>
            </li>

            <li class="mb-0">
              <router-link
                to="/terms-and-conditions"
                class="fs-14 fw-medium text-body text-decoration-none d-block py-2 px-3"
              >
                Terms and Conditions
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="col-lg-8">
      <div class="card border-0 box-shadow border-radius-10 mb-4">
        <div class="card-body p-4">
          <div
            class="card-title d-flex justify-content-between align-items-center mb-3"
          >
            <h3 class="fw-medium fs-18 mb-0">Update Password</h3>
          </div>

          <div class="mb-4">
            <div class="position-relative">
              <img
                class="w-100 h-400 rounded-top"
                src="../../../assets/images/profile-bg.jpg"
                alt="profile-bg"
              />
            </div>

            <div class="d-flex justify-content-between align-items-center p-4">
              <div class="users-profile top-125 d-flex align-items-end ms-0">
                <div class="position-relative">
                  <img
                    class="w-100 h-400"
                    :src="userImage"
                    alt="User Profile Picture"
                  />
                  <div
                    class="product-upload position-absolute bottom-0 end-0"
                    style="right: 5px !important; bottom: 5px !important"
                  >
                    <label for="file-upload" class="file-upload mb-0">
                      <img
                        src="../../../assets/images/upload-4.svg"
                        alt="upload-4"
                      />
                    </label>
       
                  </div>
                </div>
          
              </div>
            </div>
          </div>
          <div>
            <!-- Green alert for success -->
            <div
              v-if="updatePasswordState === 'success'"
              class="alert alert-success"
              role="alert"
            >
              Password updated successfully!
            </div>
            <!-- Red alert for invalid state -->
            <div
              v-if="updatePasswordState === 'invalid'"
              class="alert alert-danger"
              role="alert"
            >
              Failed to update password. Please try again.
            </div>
          </div>
          <form @submit.prevent="updatePassword">
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group mb-4">
                  <label class="label">Old Password</label>
                  <input
                    type="password"
                    class="form-control"
                    placeholder="************"
                    v-model="oldPassword"
                    :class="{ 'is-invalid': validationErrors.oldPassword }"
                  />
                  <div
                    v-if="validationErrors.oldPassword"
                    class="invalid-feedback"
                  >
                    {{ validationErrors.oldPassword }}
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mb-4">
                  <label class="label">New Password</label>
                  <input
                    type="password"
                    class="form-control"
                    placeholder="************"
                    v-model="newPassword"
                    :class="{ 'is-invalid': validationErrors.newPassword }"
                  />
                  <div
                    v-if="validationErrors.newPassword"
                    class="invalid-feedback"
                  >
                    {{ validationErrors.newPassword }}
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mb-4">
                  <label class="label">Confirm Password</label>
                  <input
                    type="password"
                    class="form-control"
                    placeholder="************"
                    v-model="confirmPassword"
                    :class="{ 'is-invalid': validationErrors.confirmPassword }"
                  />
                  <div
                    v-if="validationErrors.confirmPassword"
                    class="invalid-feedback"
                  >
                    {{ validationErrors.confirmPassword }}
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="d-flex">
                  <button type="submit" class="default-btn">
                    Update Password
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from '../../../store/auth';

export default {
  name: 'ChangePassword',
  data() {
    return {
      userInfo: [],
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      validationErrors: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      userData: ""
    };
  },
  computed: {
    updatePasswordState() {
      const authStore = useAuthStore();
      return authStore.updatePasswordState;
    },
    userImage() {
      // Check if userData is available
      if (this.userData && this.userData.img) {
        // Return user image if available
        return this.userData.img;
      } else {
        // Return default image if userData or user image is not available
        return require('../../../assets/images/publicimages/default-profile.png');
      }
    },
  },
  mounted() {
    const authStore = useAuthStore();
    this.userInfo = authStore.user;
  },
  methods: {
    updatePassword() {
      this.validationErrors = {};
      if (!this.oldPassword) {
        this.validationErrors.oldPassword = 'Old Password is required.';
      }
      if (!this.newPassword) {
        this.validationErrors.newPassword = 'New Password is required.';
      } else if (this.newPassword.length < 8) {
        this.validationErrors.newPassword =
          'New Password must be at least 8 characters long.';
      }
      if (!this.confirmPassword) {
        this.validationErrors.confirmPassword = 'Confirm Password is required.';
      }
      if (this.newPassword !== this.confirmPassword) {
        this.validationErrors.confirmPassword =
          'New Password and Confirm Password must match.';
      }

      if (Object.keys(this.validationErrors).length > 0) {
        return;
      }
      this.updateData();
    },

    async updateData() {
      try {
        const authStore = useAuthStore();

        await authStore.updatePassword(
          authStore.user.id,
          this.oldPassword,
          this.newPassword,
          this.confirmPassword
        );
      } catch (error) {
        // Handle error if necessary
      }
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
