<template>
  <div id="preloader">
    <div class="preloader">
      <img src="../../assets/pre-loader.gif" />
      <div class="waviy">
        <img src="../../assets/images/logo-box.png" class="logo" />
      </div>
    </div>
  </div>
</template>
<style scope>
/* Adjust the size of the logo */
.logo {
  width: 200px; /* Specify the width */
  height: auto; /* Automatically adjust the height to maintain aspect ratio */
}
</style>
<script>
export default {
  name: 'PreLoader',
};
</script>
