import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../../assets/images/logo-box-2.png'


const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "centered-box" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "box" }
const _hoisted_5 = {
  key: 0,
  class: "alert alert-danger",
  role: "alert"
}
const _hoisted_6 = {
  key: 1,
  class: "alert alert-success",
  role: "alert"
}
const _hoisted_7 = {
  key: 2,
  class: "alert alert-danger",
  role: "alert"
}
const _hoisted_8 = { class: "form-group mb-4" }
const _hoisted_9 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_10 = { class: "form-group mb-4" }
const _hoisted_11 = { class: "password-wrapper position-relative" }
const _hoisted_12 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_13 = { class: "form-group mb-4 d-flex justify-content-between" }

import SubHeader from "../../components/Layouts/SubHeader.vue";
import { useAuthStore } from "../../store";
import { useRouter } from "vue-router";
import { useMailStore } from "@/store/mail";
import { ref, onMounted, computed } from "vue";
import Cookies from "js-cookie";


export default /*@__PURE__*/_defineComponent({
  __name: 'SignInPage',
  setup(__props) {

const store = useAuthStore();
const router = useRouter();

const email = ref("");
const password = ref("");
let isValidEmail = true;
let isValidPassword = true;
const errorMessage = ref("");
let successMessage = "";

const checkAuth = () => {
  const token = Cookies.get("token");
  if (token) {
    router.push("/dashboard");
  }
};

const validateEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const signIn = async () => {
  isValidEmail = validateEmail(email.value);
  isValidPassword = password.value.length >= 8;

  if (isValidEmail && isValidPassword) {
    try {
      await store.signIn(email.value, password.value);
      //Cookies.set('token', response.token);
      router.push("/dashboard");
      checkAuth();
    } catch (error) {
      errorMessage.value = "Invalid email/password. Please try Again.";
      successMessage = "";

      setTimeout(() => {
        errorMessage.value = "";
      }, 5000);
    }
  } else {
    email.value = "";
    password.value = "";
    errorMessage.value = "Invalid Input";
    successMessage = "";

    setTimeout(() => {
      errorMessage.value = "";
    }, 3000);
  }
};

const sendEmailVerifications = async () => {
  const mailStore = useMailStore();
  await mailStore.resendVerificationEmail();
};

onMounted(() => {
  checkAuth();
});

const signInStatus = computed(() => {
  // Return true/false based on your condition
  return useAuthStore().signInStatus;
});

const resendEmailVerificationState = computed(() => {
  // Return true/false based on your condition
  return useMailStore().resendEmailVerificationState;
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(SubHeader),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[8] || (_cache[8] = _createStaticVNode("<div class=\"text-center mb-1\" data-v-b7fb7c3c><h4 class=\"fs-20 fw-semibold mb-2\" data-v-b7fb7c3c>DALA Smart Lockbox</h4><img class=\"rounded-circle mb-3\" src=\"" + _imports_0 + "\" alt=\"DALA-logo\" data-v-b7fb7c3c></div><div class=\"text-center mb-4\" data-v-b7fb7c3c><h4 class=\"fs-20 fw-semibold mb-2\" data-v-b7fb7c3c>Sign In</h4></div>", 2)),
            (errorMessage.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(errorMessage.value), 1))
              : _createCommentVNode("", true),
            (resendEmailVerificationState.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, " A verification email has been sent to your registered email address. Please check your inbox and click on the link provided to verify your email "))
              : _createCommentVNode("", true),
            (signInStatus.value && resendEmailVerificationState.value === null)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _cache[2] || (_cache[2] = _createTextVNode(" Email not verified. If you need to request for email verification kindly click: ")),
                  _createElementVNode("a", {
                    onClick: sendEmailVerifications,
                    style: {"cursor":"pointer","color":"blue","text-decoration":"underline"}
                  }, "Request for email verification.")
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("form", {
              onSubmit: _withModifiers(signIn, ["prevent"]),
              class: "signup-form"
            }, [
              _createElementVNode("div", _hoisted_8, [
                _cache[3] || (_cache[3] = _createElementVNode("label", { class: "label" }, "Email Address", -1)),
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
                  type: "email",
                  class: "form-control",
                  placeholder: "example@gmail.com"
                }, null, 512), [
                  [_vModelText, email.value]
                ]),
                (!_unref(isValidEmail))
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, "Invalid Email format"))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_10, [
                _cache[4] || (_cache[4] = _createElementVNode("label", { class: "label" }, "Your Password", -1)),
                _createElementVNode("div", _hoisted_11, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((password).value = $event)),
                    type: "password",
                    id: "password",
                    class: "form-control",
                    placeholder: "Password"
                  }, null, 512), [
                    [_vModelText, password.value]
                  ]),
                  (!_unref(isValidPassword))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_12, "Password must be at least 8 characters"))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "c-form-check form-check" }, [
                  _createElementVNode("input", {
                    class: "form-check-input",
                    type: "checkbox",
                    value: "",
                    id: "flexCheckDefault"
                  }),
                  _createElementVNode("label", {
                    class: "form-check-label fs-14 text-body ms-0",
                    for: "flexCheckDefault"
                  }, " Remember me ")
                ], -1)),
                _createVNode(_component_router_link, {
                  to: "/forget-password",
                  class: "text-primary fs-14 text-decoration-none"
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode(" Forget your password? ")
                  ])),
                  _: 1
                })
              ]),
              _cache[7] || (_cache[7] = _createElementVNode("div", { class: "form-group mb-4" }, [
                _createElementVNode("button", {
                  type: "submit",
                  class: "default-btn w-100 d-block"
                }, " Sign In ")
              ], -1))
            ], 32)
          ])
        ])
      ])
    ])
  ]))
}
}

})