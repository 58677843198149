<template>
  <div class="wrapper">
    <div class="card border-0 box-shadow border-radius-10 mb-4">
      <div class="card-body p-4">
        <div
          class="card-title d-flex justify-content-between align-items-center mb-3"
        >
          <h3 class="fw-medium fs-18 mb-0">My Deliveries</h3>
        </div>
        <div class="all-table scroll-bar h-660">
          <table class="table align-middle mb-0">
            <thead>
              <tr>
                <th
                  class="text-dark fw-medium pt-0 pb-2 fs-14 ps-0"
                  scope="col"
                >
                  Delivery Number
                </th>
                <th class="text-dark fw-medium pt-0 pb-2 fs-14" scope="col">
                  Locker Number
                </th>
                <th class="text-dark fw-medium pt-0 pb-2 fs-14" scope="col">
                  Delivery Date
                </th>
                <th class="text-dark fw-medium pt-0 pb-2 fs-14" scope="col">
                  Date Collected
                </th>
                <th class="text-dark fw-medium pt-0 pb-2 fs-14" scope="col">
                  Parcel Image
                </th>
                <th class="text-dark fw-medium pt-0 pb-2 fs-14" scope="col">
                  Courier Image
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="delivery in deliveryDataHistory" :key="delivery.id">
                <td class="ps-0 d-flex align-items-center">
                  <img
                    class="wh-30 rounded-circle"
                    src="../../assets/images/dala-box/dala-box.png"
                  />
                  <h5 class="fs-14 text-dark mb-0 ms-2 fw-medium">
                    Delivery ID: #{{ delivery.id }}
                  </h5>
                </td>
                <td>
                  <h5 class="fs-14 text-dark mb-0 ms-2 fw-medium">
                    Locker No. {{ delivery.locker.number }}
                  </h5>
                </td>
                <td>
                  <h5 class="fs-14 text-dark mb-0 ms-2 fw-medium">
                    {{ convertToGMT8(delivery.delivered_at) }}
                  </h5>
                </td>
                <td>
                  <h5
                    class="fs-14 text-dark mb-0 ms-2 fw-medium"
                    v-if="delivery.collected_at"
                  >
                    {{ convertToGMT8(delivery.collected_at) }}
                  </h5>
                </td>
                <td>
                  <img
                    class="wh-30 thumbnail"
                    :src="delivery.parcel_img_full_url"
                    alt="Parcel Image"
                    data-bs-toggle="modal"
                    data-bs-target="#openParcelImage"
                    @click="setParcelImageURL(delivery.parcel_img_full_url)"
                  />
                </td>
                <td>
                  <img
                    class="wh-30 thumbnail"
                    :src="delivery.face_img_full_url"
                    data-bs-toggle="modal"
                    data-bs-target="#openCourierImage"
                    @click="setFaceImageURL(delivery.face_img_full_url)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- Vertically Centered -->
    <div
      class="modal fade"
      id="openParcelImage"
      tabindex="-1"
      aria-labelledby="openParcelImageModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="openParcelImageModalLabel">
              Your Package Delivery
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="btnDismissModal"
            ></button>
          </div>
          <div class="modal-body">
            <img
              :src="parcelImgURL"
              alt="Modal Image"
              style="max-width: 100%; max-height: 100vh"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Vertically Centered -->
    <div
      class="modal fade"
      id="openCourierImage"
      tabindex="-1"
      aria-labelledby="openCourierImageModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="openCourierImageModalLabel">
              Your Delivery Guy
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="btnDismissModal"
            ></button>
          </div>
          <div class="modal-body">
            <img
              :src="faceImgURL"
              alt="Modal Image"
              style="max-width: 100%; max-height: 100vh"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useHistoryStore } from "../../store/history";

export default {
  data() {
    return { deliveryDataHistory: [], parcelImgURL: "", faceImgURL: "" };
  },
  mounted() {
    this.getLockerDeliveryHistory();
  },
  created() {
    this.getLockerDeliveryHistory();
  },
  methods: {
    async getLockerDeliveryHistory() {
      const historyStore = useHistoryStore();

      await historyStore.fetchDeliveryHistory();
      this.deliveryDataHistory = historyStore.getDeliveryHistory;

      //console.log(this.deliveryDataHistory);
    },
    convertToGMT8(dateTimeString) {
      const date = new Date(dateTimeString); // Create a Date object from the given date string
      const gmtOffset = 8; // GMT+8 timezone offset
      const gmt8Date = new Date(date.getTime() + gmtOffset * 60 * 60 * 1000); // Add offset in milliseconds

      // Format the date as desired (e.g., "05 Feb, 2023")
      const options = {
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
        timeZone: "Asia/Singapore", // Assuming GMT+8 represents the Singapore timezone
      };
      return gmt8Date.toLocaleString("en-US", options);
    },
    setParcelImageURL(url) {
      this.parcelImgURL = url;
      //console.log(url);
    },
    setFaceImageURL(url) {
      this.faceImgURL = url;
      //console.log(url);
    },
  },
};
</script>

<style scoped>
.thumbnail {
  width: 70px; /* Adjust as needed */
  height: 50px; /* Adjust as needed */
}
</style>
