import { defineStore } from "pinia";
import axios, { AxiosError, AxiosResponse } from "axios";
import { useAuthStore } from "./auth";

const baseUrl = process.env.VUE_APP_BASE_API_URL;
interface ErrorResponse {
  status: number;
  errors: any;
  data?: {
    message: string;
  };
  message: string;
  response?: AxiosResponse;
}
export const useMailStore = defineStore({
  id: "mail",
  state: () => ({
    emailVerificationState: null as string | null,
    resendEmailVerificationState: null as string | null,
    resendEmailVerificationError: null as string | null,
  }),
  actions: {
    async verifyEmail(email: string, token: string) {
      try {
        const emaildata = JSON.stringify({ email, token });

        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${baseUrl}/api/v1/verify/email`,
          headers: {
            "Content-Type": "application/json",
          },
          data: emaildata,
        };

        const response = await axios.request(config);
        this.emailVerificationState = response.data.message;
      } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError<ErrorResponse>;
          //console.log('Error status code:', axiosError.response?.status);
          this.emailVerificationState = String(axiosError.response?.status);
        } else if (error instanceof Error) {
          // Handle normal errors here
          //console.error('Normal error:', error.message);
        }
      }
    },
    async resendVerificationEmail() {
      try {
        const authStore = useAuthStore();
        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${baseUrl}/api/v1/request-email-verification`,
          headers: {
            Authorization: `Bearer ${authStore.getToken}`,
          },
        };

        const response = await axios.request(config);
        this.resendEmailVerificationState = response.data.message;
        //console.log(JSON.stringify(response.data));
      } catch (error: any) {
        //console.log("error", error.response.data.message);
        this.resendEmailVerificationError = error.response.data.message;
      }
    },
  },
  getters: {},
});
