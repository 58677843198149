<template>
  <div class="card border-0 box-shadow border-radius-10 mb-4">
    <div class="card-body p-4 m-2">
      <div class="card-title mb-4">
        <h3 class="fw-medium fs-18 mb-0">Notifications</h3>
      </div>
      <ul class="ps-0 mb-0 list-unstyled">
        <li class="border-bottom border-color pb-4 mb-4">
          <div class="d-flex">
            <div class="flex-shrink-0">
              <img
                class="wh-50 rounded-circle"
                src="../../assets/images/user/user-27.jpg"
                alt="user-27"
              />
            </div>
            <div class="flex-grow-1 ms-3">
              <h4 class="fs-14 fw-medium mb-1">Rufo Marasigan Jr.</h4>
              <p class="text-body mb-1 fs-14">"Added New Transaction!"</p>
              <div class="d-flex align-items-center">
                <vue-feather
                  type="clock"
                  style="width: 16px; stroke: #5c61f2"
                ></vue-feather>
                <span class="ms-1 text-body">08:30AM 17 Feb, 2023</span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotificationsContent',
};
</script>
