<!-- MobileVerification.vue -->
<template>
  <div class="h-80vh">
    <div class="d-table">
      <div class="d-table-cell">
        <div
          class="card border-0 box-shadow border-radius-10 mb-4 w-100"
          style="max-width: 500px; margin: auto"
        >
          <div class="card-body p-30">
            <div class="text-center mb-4">
              <img
                class="rounded-circle mb-3 centered-image"
                src="../../../assets/images/logo-box-2.png"
                alt="DALA-logo"
              />
              <h4 class="fs-20 fw-semibold mb-2">DALA Smart Lockbox</h4>

              <div class="initial-content" v-if="!showConfirmOTP">
                <p class="fs-14 text-body mb-4">
                  To complete your Authentication, please click the button below
                  to request for Mobile Verification.
                </p>

                <img
                  class="rounded-circle"
                  src="../../../assets/images/mobile.png"
                  alt="mobile"
                />
              </div>
              <div>
                <div class="form-group" v-if="!showConfirmOTP">
                  <button
                    @click="handleRequestMobileVerification()"
                    class="default-btn w-100 d-block"
                  >
                    Request Mobile Verification
                  </button>
                </div>
              </div>
              <!----After confirming OTP----->
              <div class="confirm-otp" v-if="showConfirmOTP">
                <p class="fs-14 text-body mb-4">
                  An OTP was sent to your registered mobile number. Please input
                  the OTP below and click "Verify OTP"
                </p>
                <img
                  class="rounded-circle"
                  src="../../../assets/images/mobile.png"
                  alt="mobile"
                />
                <!-- Display danger alert if OTP field is empty -->
                <div
                  v-if="
                    otpVerificationResponse !== 'invalid' &&
                    otpVerificationResponse !== null
                  "
                  class="alert alert-success"
                >
                  OTP Verification Success. Please Relogin to complete.
                </div>
                <div
                  v-else-if="otpVerificationResponse !== null"
                  class="alert alert-danger"
                >
                  Verification Failed! Invalid or expired OTP!
                </div>
                <div
                  class="alert alert-danger"
                  role="alert"
                  v-show="showOTPRequired"
                  style="text-align: left"
                >
                  OTP is required
                </div>
                <div>
                  <div class="form-group">
                    <div class="form-group mb-4">
                      <div class="password-wrapper position-relative">
                        <input
                          type="text"
                          id="otp"
                          class="form-control"
                          placeholder="Input Your OTP Here"
                          v-model="otp"
                          @input="onOTPInput"
                          required
                        />
                      </div>
                    </div>
                    <button
                      @click="verifyOTP()"
                      class="default-btn w-100 d-block"
                    >
                      Verify OTP
                    </button>
                    <br />
                    <div v-if="showResendLink" style="text-align: right">
                      <a
                        @click="resendOTP()"
                        style="
                          cursor: pointer;
                          text-decoration: underline;
                          color: rgb(5, 58, 40);
                          font-size: 16px;
                        "
                        >Resend OTP</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMobileStore } from "../../../store/mobile";
import { useAuthStore } from "../../../store/auth";

export default {
  name: "MobileVerification",
  data() {
    return {
      showConfirmOTP: false,
      otp: "",
      showOTPRequired: false,
      otpId: "",
      showResendLink: false,
    };
  },
  mounted() {
    // Show the "Resend OTP" link after 10 seconds
  },
  computed: {
    verificationResponse() {
      return useMobileStore().verificationResponse;
    },
    otpVerificationResponse() {
      return useMobileStore().otpVerificationResponse;
    },
  },
  methods: {
    async handleRequestMobileVerification() {
      this.otp = await useMobileStore().requestMobileVerify();
      setTimeout(() => {
        this.showResendLink = true;
      }, 5000); // 10 seconds in milliseconds
      if (this.verificationResponse === "Successfully sent OTP") {
        this.showConfirmOTP = true;
      }
    },
    async verifyOTP() {
      if (!this.otp.trim()) {
        this.showOTPRequired = true;
        return;
      }
      this.showOTPRequired = false;
      await useMobileStore().verifyOTP(this.otp);
      if (this.otpVerificationResponse === "invalid") {
        this.otpVerificationResponseShow = true;
      } else {
        const authStore = useAuthStore();
        authStore.logout();
      }
      //console.log('otpVerificationResponse:', this.otpVerificationResponse, typeof this.otpVerificationResponse);
    },
    onOTPInput() {
      if (this.otp.trim()) {
        this.showOTPRequired = false;
      }
    },
    resendOTP() {
      this.showResendLink = false;
      this.showConfirmOTP = false;
    },
  },
};
</script>
<style lang="scss">
/* Add this CSS to your component's style section */
.h-100vh {
  height: 100vh;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}
</style>
