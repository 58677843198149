<template>
    <div>
      <SubHeader />
      <div class="centered-box">
        <div class="box">
          <div class="card-body p-30">
            <img
              class="rounded-circle mb-3 centered-image"
              src="../../../assets/images/logo-box-2.png"
              alt="DALA-logo"
            />
            <div class="text-center mb-4">
              <h4 class="fs-20 fw-semibold mb-2">Welcome to Dalasmartlockbox</h4>
            </div>
            <div>
              <img
                class="rounded-circle mb-3 centered-image"
                src="../../../assets/images/email.png"
                alt="email"
              />
  
              <div
                v-if="resendEmailVerificationState === 'Successfully sent email verification'"
              >
                <h4 class="fs-18 fw-semibold mb-2 text-center text-success">
                    Successfully sent email verification!
                </h4>
              </div>
              <div
              v-if="!resendEmailVerificationState === 'Successfully sent email verification &&' && resendEmailVerificationState !== null"
              >
                <h4 class="fs-18 fw-semibold mb-2 text-center text-danger">
                  Verification Error! Email Invalid or Already verified
                </h4>
              </div>
            </div>
            <br />
        
              <div class="form-group">
                <button @click="resendEmailVerification()"
            
                  class="default-btn w-100 d-block text-center text-white text-decoration-none"
                >
                  Re-send email verifications.
                </button>
              </div>
            
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import SubHeader from '../../../components/Layouts/SubHeader.vue';
  import { useMailStore } from '@/store/mail';
  
  export default {
    name: 'ResendEmailVerifications',
    components: { SubHeader },
    computed: {
        resendEmailVerificationState() {
            return useMailStore().resendEmailVerificationState;
        },
    },
        
    methods: {
        async resendEmailVerification(){
            const mailStore = useMailStore()
        await mailStore.resendVerificationEmail()    },     
    },
  };
  </script>
  
  <style scoped>
  .centered-box {
    position: fixed;
    top: 40%;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 500px; /* Optional: Set a maximum width if needed */
    margin-left: auto;
    margin-right: auto;
    transform: translateY(-50%);
  }
  .box {
    width: 600px;
    height: 500px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 20px;
  }
  
  .text-danger {
    color: red;
  }
  
  .text-success {
    color: green;
  }
  
  .logout-button {
    padding: 10px 20px;
    background-color: blue;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .centered-image {
    display: block;
    margin: 0 auto; /* This will center the image horizontally */
  }
  </style>
  