import { defineStore } from "pinia";
import axios from "axios";
import { useAuthStore } from "./auth";

const baseUrl = process.env.VUE_APP_BASE_API_URL;

interface Locker {
  id: number;
  number: number;
  status: string;
}

export const useLockersStore = defineStore({
  id: "lockers",
  state: () => ({
    otpId: "" as string | null,
    lockers: [] as Locker[],
    myLockers: [] as Locker[],
    linkToLockerResponse: "" as string | null,
    linkToLockerError: "" as string | null,
    unlinkToLockerResponse: "" as string | null,
    unlinkToLockerError: "" as string | null,
    updatePinStatus: "" as string | null,
    updatePinStatusCode: "" as string | null,
  }),
  actions: {
    async fetchLockers() {
      try {
        const authStore = useAuthStore();

        const response = await axios.get<Locker[]>(
          `${baseUrl}/api/v1/lockers?withoutPagination=1`,
          {
            headers: {
              Authorization: `Bearer ${authStore.getToken}`,
            },
          }
        );

        const sortedLockers = response.data.sort((a, b) => a.number - b.number);

        this.lockers = sortedLockers;
      } catch (error) {
        console.error("Error fetching lockers:", error);
      }
    },
    async toggleLockerStatus(lockerId: number, status: string) {
      try {
        const authStore = useAuthStore();
        const data = JSON.stringify({
          status: status,
        });

        await axios.post(`${baseUrl}/api/v1/lockers/${lockerId}/status`, data, {
          headers: {
            Authorization: `Bearer ${authStore.getToken}`,
            "Content-Type": "application/json",
          },
        });

        //console.log('Locker status updated.');

        this.fetchLockers();
      } catch (error) {
        console.error("Error toggling locker status:", error);
      }
    },
    async linkToLocker(userId: number, lockerId: number) {
      try {
        const authStore = useAuthStore();
        const data = JSON.stringify({ locker_id: lockerId });

        const config = {
          method: "post" as const,
          maxBodyLength: Infinity,
          url: `${baseUrl}/api/v1/users/${userId}/link-to-locker`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authStore.getToken}`,
          },
          data: data,
        };

        const response = await axios.request(config);
        this.linkToLockerResponse = response.data;

        //console.log('Link to locker response:', response.data.message);
      } catch (error: any) {
        this.linkToLockerError = error.response.data;
        //console.error('Error linking to locker:', error.response.data.message);
      }
    },
    async unlinkToLocker(userId: number, lockerId: number) {
      try {
        const authStore = useAuthStore();
        const data = JSON.stringify({ locker_id: lockerId });

        const config = {
          method: "post" as const,
          maxBodyLength: Infinity,
          url: `${baseUrl}/api/v1/users/${userId}/unlink-to-locker`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authStore.getToken}`,
          },
          data: data,
        };

        const response = await axios.request(config);
        this.unlinkToLockerResponse = response.data.message;

        //console.log('unink to locker response:', response.data);
      } catch (error: any) {
        this.unlinkToLockerError = error.response.data.message;
        //console.error('Error unlinking to locker:', error.response.data.message);
      }
    },
    async fetchMyLockers() {
      try {
        const authStore = useAuthStore();
        let nextPageUrl = `${baseUrl}/api/v1/my-lockers`;
        let allMyLockers: Locker[] = [];
        while (nextPageUrl) {
          const response = await axios.get(nextPageUrl, {
            headers: {
              Authorization: `Bearer ${authStore.getToken}`,
            },
          });

          const responseData = response.data;
          const lockersFromPage = responseData[0].data;
          allMyLockers = [...allMyLockers, ...lockersFromPage];

          nextPageUrl = responseData.next_page_url;
        }

        this.myLockers = allMyLockers;
      } catch (error) {
        console.error("Error fetching my lockers:", error);
      }
    },
    async forgotPin(lockerId: number) {
      try {
        const authStore = useAuthStore();

        const data = JSON.stringify({
          _method: "PUT",
        });

        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${baseUrl}/api/v1/my-lockers/${lockerId}/forgot-pin`,

          headers: {
            Authorization: `Bearer ${authStore.getToken}`,
          },
          data: { _method: "PUT" }, // You can pass any data if needed
        };

        const response = await axios.request(config);
        this.otpId = response.data.otp.id;
      } catch (error) {
        console.error("Error requesting forgot pin:", error);
      }
    },
    async updateLockerPin(
      lockerId: number,
      newPin: number,
      otp: string,
      otpId: number
    ) {
      try {
        const authStore = useAuthStore();

        const data = JSON.stringify({
          _method: "PUT",
          new_pin: newPin,
          new_pin_confirmation: newPin,
          otp: otp,
        });

        const config = {
          method: "post" as const,
          maxBodyLength: Infinity,
          url: `${baseUrl}/api/v1/my-lockers/${lockerId}/verify-forgot-pin/${this.otpId}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authStore.getToken}`,
          },
          data: data,
        };

        const response = await axios.request(config);

        this.updatePinStatus = response.data.message;
        this.updatePinStatusCode = "200";
      } catch (error: any) {
        //console.error("Error updating locker PIN:", error);
        this.updatePinStatus = error.response.data.errors;
        this.updatePinStatusCode = error.response.status;
      }
    },
    clearUpdatePinStatus() {
      this.updatePinStatus = null;
      this.updatePinStatusCode = null;
    },
  },

  getters: {
    getLockers(): Locker[] {
      return this.lockers;
    },
    getLockerById: (state) => (id: any) => {
      return state.lockers.find((locker) => locker.number === id);
    },
  },
});
