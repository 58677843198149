<template>
  <div class="card border-0 box-shadow border-radius-10 mb-4">
    <div class="card-body p-4">
      <div
        class="card-title d-flex justify-content-between align-items-center mb-3"
      >
        <h3 class="fw-medium fs-18 mb-0">Users List</h3>
      </div>
      <div v-if="isLoading" class="text-center">
        <p>Loading...</p>
      </div>
      <div class="all-table scroll-bar h-660">
        <table class="table align-middle mb-0">
          <!-- Table headers -->
          <thead>
            <!-- Table headers -->
            <tr>
              <th class="text-dark fw-medium pt-0 pb-2 fs-14 ps-0" scope="col">
                Name
              </th>
              <th class="text-dark fw-medium pt-0 pb-2 fs-14" scope="col">
                Email
              </th>
              <th class="text-dark fw-medium pt-0 pb-2 fs-14" scope="col">
                Unit Number
              </th>
              <th class="text-dark fw-medium pt-0 pb-2 fs-14" scope="col">
                Contact Number
              </th>
              <th class="text-dark fw-medium pt-0 pb-2 fs-14" scope="col">
                Date Added
              </th>
              <th class="text-dark fw-medium pt-0 pb-2 fs-14" scope="col">
                Actions
              </th>
              <th class="text-dark fw-medium pt-0 pb-2 fs-14" scope="col"></th>
            </tr>
          </thead>

          <!-- Table body -->
          <tbody>
            <tr v-for="(user, id) in usersStore.users.data" :key="id">
              <td class="ps-0 d-flex align-items-center">
                <router-link
                  :to="`/user-profile/${user.id}`"
                  class="text-decoration-none d-flex align-items-center"
                >
                  <img
                    class="wh-30 rounded-circle"
                    src="../../../assets/images/user/user-1.jpg"
                    :alt="`user-${user.id}`"
                  />
                  <div class="d-flex flex-column ms-2">
                    <h5 class="fs-14 text-dark mb-0 fw-medium">
                      {{ user.first_name }} {{ user.last_name }}
                    </h5>
                    <span class="text-body">Home Owner</span>
                  </div>
                </router-link>
              </td>

              <td>
                <span class="text-body">{{ user.email }}</span>
                <br />
                <span
                  :class="{
                    'text-success': user.email_verified_at,
                    'text-danger': !user.email_verified_at,
                    'fs-12': true, // Add a class for smaller font size
                  }"
                >
                  {{ user.email_verified_at ? "Verified" : "Not Verified" }}
                </span>
              </td>
              <td>
                <span class="text-body">{{ user.address_house_number }}</span>
              </td>
              <td>
                <span class="text-body">{{ user.mobile }}</span>
                <br />
                <span
                  :class="{
                    'text-success': user.mobile_verified_at,
                    'text-danger': !user.mobile_verified_at,
                    'fs-12': true, // Add a class for smaller font size
                  }"
                >
                  {{ user.mobile_verified_at ? "Verified" : "Not Verified" }}
                </span>
              </td>
              <td>
                <span class="text-body">{{
                  convertToGMT8(user.created_at)
                }}</span>
              </td>
              <td>
                <span class="text-body">
                  <!-- Add classes to the Vue Feather components -->
                  <router-link
                    :to="`/update-user/${user.id}`"
                    title="Edit User profile"
                    class="edit-icon"
                  >
                    <!-- Add classes to the Vue Feather components -->
                    <vue-feather type="edit" class="feather-icon"></vue-feather>
                  </router-link>
                  <vue-feather
                    type="user-x"
                    class="feather-icon user-x-icon"
                    data-bs-toggle="modal"
                    :data-bs-target="`#modal-${user.id}`"
                    title="Deactivate User"
                    @click="setSelectedUser(user)"
                  >
                    ></vue-feather
                  >
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Pagination controls -->
      <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center">
          <li
            class="page-item"
            :class="{ disabled: usersStore.users.current_page === 1 }"
          >
            <button class="page-link" @click="fetchPreviousPage">
              Previous
            </button>
          </li>
          <li
            v-for="page in usersStore.users.last_page"
            :key="page"
            class="page-item"
            :class="{ active: usersStore.users.current_page === page }"
          >
            <button class="page-link" @click="fetchPage(page)">
              {{ page }}
            </button>
          </li>
          <li
            class="page-item"
            :class="{
              disabled:
                usersStore.users.current_page === usersStore.users.last_page,
            }"
          >
            <button class="page-link" @click="fetchNextPage">Next</button>
          </li>
        </ul>
      </nav>
    </div>

    <!-- Vue component for Deactivate User Modal -->
    <DeactivateUserModal
      v-for="(user, index) in usersStore.users.data"
      :key="index"
      :modalId="`modal-${user.id}`"
      :selectedUserName="selectedUserName"
      :selectedUserId="selectedUserId"
      @delete-user="handleDeleteUser"
    />
  </div>
</template>

<script>
import { useUsersStore } from "@/store/users";
import DeactivateUserModal from "../../../components/Modal/DeactivateUserModal.vue";

export default {
  components: {
    DeactivateUserModal,
  },
  async mounted() {
    await this.fetchUsers();
  },
  async created() {
    await this.fetchUsers();
  },
  data() {
    return {
      isLoading: true,
      selectedUserName: "",
      selectedUserId: "",
      retryCount: 0,
      maxRetries: 3,
    };
  },
  methods: {
    async handleDeleteUser(userId) {
      try {
        const usersStore = useUsersStore();

        await usersStore.deleteUser(userId);

        //console.log('User deleted successfully');
        await this.fetchUsers();
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    },
    setSelectedUser(user) {
      this.selectedUserName = `${user.first_name} ${user.last_name}`;
      this.selectedUserId = String(user.id);
    },
    async fetchUsers(page = 1) {
      try {
        this.isLoading = true;
        this.retryCount = 0;
        const usersStore = useUsersStore();
        await usersStore.fetchUsers(page);
        if (usersStore.users.data.length > 0) {
          this.isLoading = false;
          this.setSelectedUser(usersStore.users.data[0]);
        }
      } catch (error) {
        const usersStore = useUsersStore();
        //console.error("Error fetching users:", error);
        if (this.retryCount < this.maxRetries) {
          this.retryCount++;
          console.log("retring ...", this.retryCount);
          await this.fetchUsersWithRetry(page);
        } else {
          // Reset loading state and display an error message after max retries
          this.isLoading = false;
          // You can display an error message to the user here
        }
      }
    },
    fetchPage(page) {
      this.fetchUsers(page);
    },
    fetchPreviousPage() {
      if (this.usersStore.users.current_page > 1) {
        this.fetchUsers(this.usersStore.users.current_page - 1);
      }
    },
    fetchNextPage() {
      if (
        this.usersStore.users.current_page < this.usersStore.users.last_page
      ) {
        this.fetchUsers(this.usersStore.users.current_page + 1);
      }
    },
    convertToGMT8(dateTimeString) {
      const date = new Date(dateTimeString); // Create a Date object from the given date string
      const gmtOffset = 8; // GMT+8 timezone offset
      const gmt8Date = new Date(date.getTime() + gmtOffset * 60 * 60 * 1000); // Add offset in milliseconds

      // Format the date as desired (e.g., "05 Feb, 2023")
      const options = {
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
        timeZone: "Asia/Singapore", // Assuming GMT+8 represents the Singapore timezone
      };
      return gmt8Date.toLocaleString("en-US", options);
    },
  },
  computed: {
    usersStore() {
      return useUsersStore();
    },
  },
};
</script>

<style scoped>
/* CSS for pagination controls */
.pagination {
  margin-top: 20px;
}

.page-link {
  cursor: pointer;
}

.page-item.disabled .page-link {
  pointer-events: none;
}

.page-item.disabled .page-link:hover {
  cursor: default;
  background-color: transparent;
  border-color: #dee2e6;
  color: #6c757d;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.feather-icon {
  cursor: pointer;
}

.feather-icon:hover {
  filter: brightness(0.8); /* Adjust brightness on hover for a subtle effect */
}

.edit-icon {
  padding-right: 10px; /* Add right padding to the edit icon */
}
</style>
