<template>
  <div class="card border-0 box-shadow border-radius-10 mb-4">
    <div class="card-body p-0">
      <div class="position-relative">
        <img
          class="w-100 h-400"
          src="../../../assets/images/profile-bg.jpg"
          alt="ad-user-bg-two"
        />
      </div>

      <div class="d-flex justify-content-between align-items-center p-4">
        <div class="users-profile top-125 d-flex align-items-end ms-0">
          <div class="position-relative">
            <img
              class="w-100 h-400"
              src="../../../assets/images/publicimages/default-profile.png"
              alt="User Profile Picture"
            />
            <div
              class="product-upload position-absolute bottom-0 end-0"
              style="right: 5px !important; bottom: 5px !important"
            ></div>
          </div>
          <div class="ifno ms-3 mb-2">
            <h4 class="fs-18 fw-medium">
              {{ userData ? userData.first_name : "" }}
              {{ userData ? userData.last_name : "" }}
            </h4>
            <span class="text-body">
              Unit Number:
              {{ userData ? userData.address_house_number : "" }}</span
            >
            <br />
            <span class="text-body">{{ userData ? userData.email : "" }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="tab-content" id="myTabContent">
    <div class="row">
      <div class="col-lg-8">
        <div class="card border-0 box-shadow border-radius-10 mb-4">
          <div class="card-body p-4">
            <div
              class="card-title d-flex justify-content-between align-items-center mb-3"
            >
              <h3 class="fw-medium fs-18 mb-0">Profile</h3>
            </div>
            <form>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group mb-4">
                    <label class="label">First Name</label>
                    <input
                      type="text"
                      class="form-control"
                      :value="userData ? userData.first_name : ''"
                      @input="updateFirstName($event.target.value)"
                      :readonly="true"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group mb-4">
                    <label class="label">Last Name</label>
                    <input
                      type="text"
                      class="form-control"
                      :value="userData ? userData.last_name : ''"
                      @input="updateLastName($event.target.value)"
                      :readonly="true"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group mb-4">
                    <label class="label">Address</label>
                    <input
                      type="text"
                      class="form-control"
                      :value="userData ? userData.address : ''"
                      @input="updateAddress($event.target.value)"
                      :readonly="true"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group mb-4">
                    <label class="label">Email Address</label>
                    <input
                      type="text"
                      class="form-control"
                      :value="userData ? userData.email : ''"
                      @input="updateEmail($event.target.value)"
                      :readonly="true"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group mb-4">
                    <label class="label">Phone</label>
                    <input
                      type="text"
                      class="form-control"
                      :value="userData ? userData.mobile : ''"
                      :readonly="true"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group mb-4">
                    <label class="label">User Type</label>
                    <input
                      type="text"
                      class="form-control"
                      :value="userData ? userData.user_type : ''"
                      @input="updateUserType($event.target.value)"
                      :readonly="true"
                    />
                  </div>
                </div>
                <!-- Add more fields as needed -->
                <div class="d-flex justify-content-start mt-4">
                  <router-link
                    v-if="userData"
                    :to="`/update-user/${userData.id}`"
                  >
                    <button class="default-btn">Edit Profile</button>
                  </router-link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { useUsersStore } from "@/store/users";
import router from "@/router";
export default {
  name: "UserProfile",
  setup() {
    const userStore = useUsersStore();
    const userData = ref(null);

    onMounted(async () => {
      const userId = router.currentRoute.value.params.id;
      try {
        await userStore.fetchUserData(userId);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
      userData.value = userStore.fetchedUserData;
    });

    const userImage = computed(() => {
      if (userData.value && userData.value.img) {
        return userData.value.img;
      } else {
        return require("../../../assets/images/avatar-1.png");
      }
    });

    const updateFirstName = (value) => {
      if (userData.value) {
        userData.value.firstName = value;
      }
    };

    const updateLastName = (value) => {
      if (userData.value) {
        userData.value.lastName = value;
      }
    };

    // Method to update address
    const updateAddress = (value) => {
      if (userData.value) {
        userData.value.address = value;
      }
    };

    // Method to update email
    const updateEmail = (value) => {
      if (userData.value) {
        userData.value.email = value;
      }
    };

    // Method to update phone
    const updatePhone = (value) => {
      if (userData.value) {
        userData.value.phone = value;
      }
    };

    const updateUserType = (value) => {
      if (userData.value) {
        userData.value.user_type = value;
      }
    };

    return {
      userData,
      userImage,
      updateFirstName,
      updateLastName,
      updateAddress,
      updateEmail,
      updatePhone,
      updateUserType,
    };
  },
};
</script>

<style scoped>
.styled-hr {
  border-top: 2px solid #ccc; /* Color of the horizontal line */
  margin-top: 20px; /* Adjust the top margin as needed */
  margin-bottom: 20px; /* Adjust the bottom margin as needed */
}
</style>
