<template>
  <div class="container-fluid">
    <div class="inner-main-content">
      <MainHeader />
      <UsersList />
      <MainSidebar />
      <MainFooter />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import UsersList from '../../components/Users/UsersList/UsersList.vue';
import MainFooter from '../../components/Layouts/MainFooter.vue';
import MainSidebar from '../../components/Layouts/MainSidebar.vue';
import MainHeader from '../../components/Layouts/MainHeader.vue';

export default defineComponent({
  name: 'UsersListPage',
  components: {
    UsersList,
    MainFooter,
    MainSidebar,
    MainHeader,
  },
});
</script>
