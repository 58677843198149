import axios from 'axios';
import { useAuthStore } from './auth';
import { defineStore } from 'pinia';

const baseUrl = process.env.VUE_APP_BASE_API_URL;

export const useDashboardStore = defineStore({
  id: 'dashboard',
  state: () => ({
    dashboardData: null as any,
    error: null as string | null,
  }),
  actions: {
    async fetchDashboardData() {
      const authStore = useAuthStore();
      try {
        if (!authStore.getToken) {
          throw new Error('Unauthorized: Token is missing');
        }

        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${baseUrl}/api/v1/dashboard`,
          headers: {
            Authorization: `Bearer ${authStore.getToken}`,
          },
        };

        const response = await axios.request(config);
        this.dashboardData = response.data;
      } catch (error: any) {
        this.error = error.message || error;
        throw new Error(error);
      }
    },
  },
  getters: {
    getDashboardData(): any {
      return this.dashboardData;
    },
    getError(): string | null {
      return this.error;
    },
  },
});
