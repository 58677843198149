User
<template>
  <div class="card border-0 box-shadow border-radius-10 mb-4">
    <div class="card-body p-4">
      <h4 class="text-dark fs-18 fw-medium mb-3">Assign Locker to a User</h4>
      <div v-if="linkToLockerResponse" class="alert alert-success" role="alert">
        {{ linkToLockerResponse.message }}
      </div>
      <div
        v-else-if="linkToLockerError"
        class="alert alert-danger"
        role="alert"
      >
        {{ linkToLockerError.message }}
      </div>
      <div class="row mb-4">
        <label class="col-sm-2 col-form-label label">Locker Number</label>
        <div class="col-sm-10">
          <input
            type="text"
            class="form-control"
            v-model="lockerNumber"
            readonly="true"
          />
        </div>
      </div>
      <div class="row mb-4">
        <label class="col-sm-2 col-form-label label">Locker Size</label>
        <div class="col-sm-10">
          <input
            type="text"
            class="form-control"
            v-model="lockerSize"
            readonly="true"
          />
        </div>
      </div>
      <div class="row mb-4">
        <label class="col-sm-2 col-form-label label">Status</label>
        <div class="col-sm-10">
          <input
            type="text"
            class="form-control"
            v-model="lockerStatus"
            readonly="true"
          />
        </div>
      </div>
      <div class="row mb-4">
        <label class="col-sm-2 col-form-label label">Locker User</label>
        <div class="col-sm-10">
          <div class="form-group mb-4">
            <select
              class="form-select form-control"
              aria-label="Default select example"
              v-model="selectedUser"
              :class="{ 'is-invalid': !selectedUser.id }"
            >
              <option selected disabled>Select User</option>
              <option
                v-for="user in allUsers"
                :value="{
                  id: user.id,
                  name: user.first_name + ' ' + user.last_name,
                }"
                :key="user.id"
              >
                {{ user.first_name }} {{ user.last_name }}
              </option>
            </select>
            <div class="invalid-feedback">Please select a user.</div>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <label class="col-sm-2 col-form-label label"></label>
        <div class="col-sm-10">
          <div class="form-group mb-0 d-flex">
            <button
              type="button"
              class="default-btn"
              data-bs-toggle="modal"
              data-bs-target="#assignUserModal"
              :disabled="selectedUser.id === null"
            >
              Save
            </button>
            <button @click="returnToList()" class="default-btn border-btn ms-3">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Vertically Centered -->
    <div
      class="modal fade"
      id="assignUserModal"
      tabindex="-1"
      aria-labelledby="assignUserModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="assignUserModalLabel">
              Assign User to a Locker
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="btnDismissModal"
            ></button>
          </div>
          <div class="modal-body">
            <p class="text-body fs-14">
              Are you sure you want to assign
              <span style="font-weight: bold; color: rgb(13, 126, 60)">
                {{ selectedUser.name }}</span
              >
              to
              <span style="font-weight: bold; color: rgb(13, 126, 60)">{{
                lockerNumber
              }}</span
              >?
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              id="btnDismissModal"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-primary" @click="assignUser()">
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useLockersStore } from "@/store/lockers";
import { useUsersStore } from "@/store/users";
import router from "../../router";

export default {
  name: "AssignLocker",
  data() {
    return {
      lockerId: "",
      assignedUser: "",
      lockerNumber: "",
      lockerSize: "",
      lockerStatus: "",
      allUsers: [],
      selectedUser: { id: null, name: "" },
    };
  },
  computed: {
    linkToLockerResponse() {
      return useLockersStore().linkToLockerResponse;
    },
    linkToLockerError() {
      return useLockersStore().linkToLockerError;
    },
  },
  methods: {
    async fetchUsers() {
      try {
        const usersStore = useUsersStore();
        await usersStore.fetchAllUsers(); // Make sure to call the action to fetch all users
        this.allUsers = usersStore.allUsers;
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    },
    async fetchLockers() {
      try {
        const lockersStore = useLockersStore();
        await lockersStore.fetchLockers();
        this.locker = lockersStore.getLockerById(
          parseInt(this.$route.params.id)
        );

        this.lockerNumber = "Locker " + this.locker.number;
        this.lockerSize = this.locker.locker_size_id;
        this.lockerId = this.locker.id;

        if (this.locker.user_id !== null) {
          this.lockerStatus = "Occupied";
        } else {
          this.lockerStatus = "Unassigned";
        }
      } catch (error) {
        console.error("Error fetching lockers:", error);
      }
    },
    returnToList() {
      router.push("/locker-list");
    },
    async assignUser() {
      try {
        await useLockersStore().linkToLocker(
          this.selectedUser.id,
          this.lockerId
        );
        if (useLockersStore().linkToLockerError) {
          document.getElementById("btnDismissModal").click();
        }
        if (useLockersStore().linkToLockerResponse) {
          document.getElementById("btnDismissModal").click();
        }
      } catch (error) {
        console.error("Error Response Data:", error);
      }
    },
  },
  mounted() {
    this.fetchLockers();
    this.fetchUsers();
  },
};
</script>

<style scoped>
.is-invalid {
  border-color: #dc3545;
}
.invalid-feedback {
  color: #dc3545;
}
</style>
